<p-dialog [baseZIndex]="2000" [style]="{ minWidth: '600px', marginTop: '30px', maxWidth: '600px'  }" [(visible)]="modal"
    [closable]="false" [closeOnEscape]="false" [dismissableMask]="false" [modal]="true" [position]="'center'"
    [draggable]="false">
    <fieldset>
        <div *ngIf="!loadingForm" class="pr-3 pl-3">
            <h3 class="text-primary bold">Zoom Appointment</h3>
            <div class="form-group mt-2">
                <button *ngIf="appointment?.zoom_meeting" role="button" class="btn btn-primary btn-lg mr-2"
                    [disabled]="processing" (click)="startMeeting()">
                    <i class="fa fa-solid fa-video mr-1"></i> Start Zoom Meeting
                </button>
                <button *ngIf="!appointment?.zoom_meeting" role="button" class="btn btn-primary btn-lg mr-2"
                    (click)="createMeeting()">
                    <i class="fas fa-plus mr-1"></i> Create Zoom Meeting
                </button>
                <button *ngIf="appointment?.zoom_meeting" role="button" class="btn btn-outline-danger btn-lg mr-2"
                    (click)="deleteMeeting()">
                    <i class="fas fa-trash mr-1"></i> Delete Zoom Meeting
                </button>

                <small *ngIf="hasError" class="p-error d-flex mt-1">Please create a Zoom Account using this email {{
                    currentUser?.email }} to use this service at</small>
                <small *ngIf="hasError" class="d-flex"><a href="https://zoom.us/signup#/signup"
                        target="_blank">https://zoom.us/signup#/signup</a></small>
            </div>
            <div class="form-group mt-2 mb-2">
                <small class="text-muted">Patient</small>
                <div class="readonly-fields name">{{ appointment?.patient?.display }}</div>
            </div>
            <div class="form-group">
                <small class="text-muted">Appointment Date</small>
                <div class="readonly-fields name">{{ appointment?.appointment_datetime_notification }}</div>
            </div>
            <div class="form-group">
                <small class="text-muted">Reason for Consultation</small>
                <div class="readonly-fields name">{{ appointment?.visit_reason }}</div>
            </div>

            <div *ngIf="appointment?.zoom_meeting" class="form-group relative">
                <small class="text-muted">Patient link</small>
                <button pButton pRipple type="button" icon="fa fa-duotone fa-copy"
                    (click)="copyLink(appointment?.zoom_meeting?.join_url)"
                    class="p-button-rounded p-button-text absolute right-0 top-1.5"></button>
                <textarea rows="2" cols="30" pInputTextarea class="form-control w-full" [readonly]="true"
                    [autoResize]="true"
                    (click)="copyLink(appointment?.zoom_meeting?.join_url)">{{ appointment?.zoom_meeting?.join_url }}</textarea>
            </div>
            <div *ngIf="appointment?.zoom_meeting" class="form-group">
                <small class="text-muted">Patient/Guest emails</small>

                <p-chips [(ngModel)]="emails" separator=","
                    placeholder="Hint: email1@ex.com, email2@ex.com, email3@ex.com" addOnBlur="true"
                    [ngClass]="{'ng-invalid ng-dirty' : hasError}" (onBlur)="validateEmails()"
                    (onFocus)="validateEmails()" (onAdd)="validateEmails()" (onRemove)="validateEmails()"></p-chips>
                <small *ngIf="hasError" class="p-error">Please provide a valid email
                    address.</small>
            </div>
        </div>
        <div *ngIf="loadingForm" class="loading-form">
            <i class=" ml-2 nav-icon fas fa-circle-notch spinning text-base"></i>
        </div>
    </fieldset>
    <p-footer>
        <div class="w-100 text-right pr-3 pl-3 pb-3">
            <button *ngIf="!loadingForm && appointment?.zoom_meeting" role="button" class="btn btn-primary btn-lg mr-2"
                (click)="emailZoomLink()" [disabled]="processing">
                <i class="far fa-paper-plane mr-1"></i>
                Email zoom link
                <i *ngIf="processing" class="ml-2 nav-icon fas fa-circle-notch spinning"></i>
            </button>
            <button role="button" class="btn btn-outline-primary btn-lg" (click)="onClose()" [disabled]="processing">
                Close
            </button>
        </div>
    </p-footer>
</p-dialog>