<p-dialog
  [baseZIndex]="2000"
  [(visible)]="modal"
  [style]="{ maxWidth: '600px' }"
  [modal]="true"
  position="center"
  [draggable]="false"
  [dismissableMask]="false"
  [closable]="false"
  closeIcon=""
>
    <div class="p-5">
        <div class="d-flex justify-content-center align-items-center flex-column w-100">
            <div class="icon d-flex justify-content-center align-items-center"><i class="fas fa-envelope-square"></i></div>
            <h5 class="mt-4">Didn't Receive the email?</h5>
        </div>
        <p class="mt-4">If you have not received the verification link after several attemps, please try the following: </p>
        <ul style="list-style: decimal;">
            <li>Make sure that the email address that you have entered is correct.</li>
            <li>Check if it is in your email's junk or spam folder.</li>
            <li>The message may have been delayed. Please check your email again after 20-30 minutes.</li>
        </ul>
    </div>
    <p-footer>
        <div class="w-100 pr-3 pl-3 pb-3">
            <button class="btn btn-primary btn-lg w-100" (click)="modal = false">
                Okay
            </button>
        </div>
    </p-footer>
</p-dialog>
