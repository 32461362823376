<button
  *ngIf="!hideTemplateButton.includes(currentForm)"
  class="btn btn-default btn-sm"
  (click)="onOpen()"
  style="border: 1px solid #aba0a0; border-radius: 10px;"
>
  Insert Template
</button>

<p-dialog
  header="Select Text Template"
  [baseZIndex]="2000"
  [style]="{ width: '50vw', marginTop: '30px' }"
  [(visible)]="modal"
  [closable]="false"
  [closeOnEscape]="false"
  [modal]="true"
  [position]="'top'"
  [draggable]="false"
>
  <p> Please select the text template that you wish to use.</p>
  <section>
    <form [formGroup]="searchForm">
      <div class="form-row">
        <div class="col">
          <small>Template</small>
          <div class="input-group mb-3" style="position: relative">
            <b *ngIf="searchForm.value.query"
              ><span
                class="fa fa-times empty-query"
                (click)="
                  templates = originalTemplates;
                  typeSelect.value = 'All';
                  searchForm.reset()
                "
              ></span
            ></b>
            <input
              class="form-control"
              #search
              formControlName="query"
              type="text"
              class="form-control"
              placeholder="Search Template Name"
              style="padding-right: 22px"
            />
            <div class="input-group-append">
              <button
                class="btn btn-secondary"
                type="button"
                (click)="searchTemplate()"
                style="border-top-right-radius: 10px; border-bottom-right-radius: 10px;"
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div class="col">
          <small>Template Type</small>
          <select
            class="form-control"
            (change)="selectGroup($event.target.value)"
            #typeSelect
          >
            <option>All</option>
            <option value="custom">Custom</option>
            <option value="global">Global</option>
            <!-- <option *ngFor="let group of getTypes()" [value]="group">
              {{ group }}
            </option> -->
          </select>
        </div>
      </div>
    </form>
    <div class="clearfix"></div>
    <table class="table table-striped table-templates">
      <thead>
        <!-- <th align="center">Code</th> -->
        <th align="center">Template Name</th>
        <!-- <th align="center" style="width: 150px">Template Type</th> -->
      </thead>
      <tbody *ngIf="templates">
        <tr
          *ngFor="let template of templates"
          (click)="selectedTemplate = template"
          [class.selected]="selectedTemplate?.id === template.id"
        >
          <!-- <td>{{ template.code }}</td> -->
          <td>{{ template.name }}</td>
          <!-- <td>{{ template.type }}</td> -->
        </tr>
        <tr>
          <td
            colspan="3"
            *ngIf="templates.length < 1"
            style="font-weight: normal !important"
          >
            No Text Template Found.
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!templates">
        <tr class="no-hover">
          <td colspan="3" style="font-weight: normal !important">
            Loading Templates
          </td>
        </tr>
      </tbody>
    </table>
  </section>
  <section class="clearfix">
    <hr />
    <label>Template Preview</label>
    <div class="col-md-12 mt-4">
      <label [innerHTML]="title"></label>
      <div class="preview" [innerHTML]="selectedTemplate?.content"></div>
    </div>
  </section>
  <p-footer>
    <div class="text-right mt-2">
      <button class="btn btn-lg btn-primary mr-3" (click)="onInsertTemplate()">
        Insert Template
      </button>
      <button class="btn btn-lg btn-light bordered-gray" (click)="onClose()">
        Close
      </button>
    </div>
  </p-footer>
</p-dialog>
