import { Component } from '@angular/core';
import { ADB2C_CODE } from './utils/items/storage-names';
import { version, name, copyright } from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  version: string = version;
  appName: string = name;
  copyright: string = copyright;

  constructor() {
    let url = location.href;
    let codeIndex = url.indexOf('code');

    if (url.indexOf('code') !== -1) {
      let code = url.substring(codeIndex + 5, url.length).trim();
      localStorage.setItem(ADB2C_CODE, code)
    }
  }
}
