import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import SignaturePad, { Options, PointGroup } from 'signature_pad';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {isAcceptableImage} from '../../../utils/helper/utilities';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-user-signature-pad',
  templateUrl: './user-signature-pad.component.html',
  styleUrls: ['./user-signature-pad.component.scss']
})
export class UserSignaturePadComponent implements OnInit {

  @Output()
  drawAgain: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onDrawComplete: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  preview: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  source: any;

  signaturePad: any;

  signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 2,
    dotSize: 3,
    minDistance: 20,
    canvasWidth: 500,
    canvasHeight: 300,
    // velocityFilterWeight: 20,
    backgroundColor: 'rgba(255, 255, 255, 0)'
  };

  constructor(
    private toastr: ToastrService
  ) { }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad = new SignaturePad(document.getElementById('signature-pad') as HTMLCanvasElement, this.signaturePadOptions);
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  ngOnInit(): void {
  }

  save() {
  }

  drawComplete(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toData());
    // console.log(this.signaturePad.toDataURL());
    this.onDrawComplete.emit(this.signaturePad.toDataURL());
  }

  drawStart(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('Start drawing', event);
  }

  clear() {
    this.signaturePad.clear();
    this.onDrawComplete.emit(null);
  }

  reDraw() {
    this.source = null;
    this.drawAgain.emit();
  }

  previewUploadFile(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (!isAcceptableImage(file?.type)) {
        this.toastr.error('File should be of type image', '', { timeOut: 5000 });
        return;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.preview.emit({
          file,
          name: file?.name,
          preview: this.source = reader.result as string
        });
      };
    }
  }
}
