<p-dialog
  [baseZIndex]="2000"
  [(visible)]="modal"
  [style]="{ maxWidth: '600px' }"
  [modal]="true"
  position="center"
  [draggable]="false"
  [dismissableMask]="false"
  [closable]="false"
  closeIcon=""
>
    <div class="p-5">
        <div class="d-flex justify-content-center align-items-center flex-column w-100" *ngIf="!processing" >
            <div class="icon d-flex justify-content-center align-items-center"><i class="fas fa-envelope-square"></i></div>
            <h5 class="mt-4">Verification link has been sent.</h5>
        </div>
        <div class="d-flex justify-content-center align-items-center flex-column w-100" *ngIf="processing" >
            <i class="fas fa-circle-notch spinning verify-spinner"></i>
            <h5 class="mt-4">Processing your request</h5>
        </div>
    </div>
    <p-footer>
        <div class="w-100 pr-3 pl-3 pb-3" *ngIf="!processing">
            <button class="btn btn-primary btn-lg w-100" (click)="modal = false">
                Okay
            </button>
        </div>
    </p-footer>
</p-dialog>
