import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { getStorage } from '../helper/storage';
import { CURRENT_USER, TOKEN } from '../items/storage-names';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  user: any;
  userSubject: any = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private socialAuthService: SocialAuthService,
  ) {
    this.user = this.getCurrentUser();
  }

  getCurrentUser() {
    return getStorage(CURRENT_USER, true);
  }

  setUser(user) {
    this.userSubject.next(this.user = user);
  }

  login(token) {
    localStorage.setItem(TOKEN, token);
    this.router.navigate(['/dashboard']);
  }

  logout(redirect: boolean = true) {
    // this.socialAuthService.signOut(true);
    this.user = null;
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(CURRENT_USER);

    if (redirect) {
      setTimeout(() => {
        window.location.href = environment.adb2c_logout_url;
      }, 1);
    }
  }

  storeUser(user) {
    localStorage.setItem(CURRENT_USER, JSON.stringify(user));
  }

  updateUserSubscription(subscription: any) {
    this.user = this.getCurrentUser();
    this.user.active_subscription = subscription;
    this.storeUser(this.user);
    return this.user;
  }

  reload(routerUrl: string) {
    const url = routerUrl;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(url);
    });

  }
}
