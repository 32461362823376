<form [formGroup]="form" *ngIf="form">
  <fieldset [disabled]="processing">
    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-between">
          <span class="bold">IMMUNIZATION HISTORY</span>
          <a class="cursor-pointer" (click)="editMode = true" *ngIf="!editMode && !processing">Edit
            Details</a>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-bordered" *ngIf="!editMode">
          <tbody style="border: none">
            <tr style="border: none">
              <td class="no-border bold" style="width: 20% !important;">Vaccine Name</td>
              <td class="no-border bold" style="width: 20% !important;">Brand Name</td>
              <td class="no-border bold">Series</td>
              <td class="no-border bold">Site Given</td>
              <!-- <td class="no-border bold">Dose No.</td>
              <td class="no-border bold">Booster No.</td> -->
              <td class="no-border bold">Route</td>
              <td class="no-border bold">Next Dose On</td>
            </tr>
          </tbody>
          <tbody skeleton-loader [rows]="3" [columns]="7" *ngIf="processing"></tbody>
          <tbody *ngIf="!processing">
            <tr *ngFor="let item of items?.data">
              <td>{{ item?.vaccine }}</td>
              <td>{{ item?.brand_name }}</td>
              <td>{{ item?.series }}</td>
              <td>{{ item?.site_given }}</td>
              <!-- <td>{{ item?.dose_no }}</td>
              <td>{{ item?.booster_no }}</td> -->
              <td>{{ item?.route }}</td>
              <td>{{ item?.next_dose_on | date: 'mediumDate' }}</td>
            </tr>
            <tr *ngIf="items?.data?.length < 1">
              <td colspan="7">No Immunizations</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="editMode">
          <div *ngFor="let control of immunizations?.controls; let i = index" [formGroup]="control"
            class="add-immunization">
            <div class="form-row">
              <div class="col">
                <small class="text-muted">Vaccine Name <span class="text-danger">*</span></small>
                <input type="text" class="form-control" formControlName="vaccine"
                  [class.is-invalid]="immunizations.at(i).get('vaccine').invalid && (immunizations.at(i).get('vaccine').dirty)" />
              </div>
              <div class="col">
                <small class="text-muted">Brand Name</small>
                <input type="text" class="form-control" formControlName="brand_name" />
              </div>
              <div class="col">
                <small class="text-muted">Series</small>
                <select name="series" id="series" class="form-control" formControlName="series">
                  <option value="null" hidden>Select Series</option>
                  <option value="{{item.value}}" *ngFor="let item of series">{{item.display}}</option>
                </select>
              </div>
              <div class="col">
                <small class="text-muted">Dose (mL)</small>
                <input type="text" class="form-control" appDigitOnlyDirective decimal="true"
                  formControlName="dose_ml" />
              </div>
              <div class="col">
                <small class="text-muted">Site Given</small>
                <input type="text" class="form-control" formControlName="site_given" />
              </div>
              <div class="col">
                <small class="text-muted">Route</small>
                <input type="text" class="form-control" formControlName="route" />
              </div>
              <!-- <div class="col">
                <small class="text-muted">Dose No.</small>
                <input type="number" class="form-control" formControlName="dose_no" />
              </div>
              <div class="col">
                <small class="text-muted">Booster No.</small>
                <input type="number" class="form-control" formControlName="booster_no" />
              </div> -->
            </div>
            <div class="form-row">
              <div class="col">
                <small class="text-muted">Lot No.</small>
                <input type="text" class="form-control" formControlName="lot_no" />
              </div>
              <div class="col">
                <small class="text-muted">Administered By (if not self)</small>
                <input type="text" class="form-control" formControlName="administered_by" />
              </div>
              <div class="col">
                <small class="text-muted">Administered On <span class="text-danger">*</span></small>
                <input type="date" class="form-control" formControlName="administered_on"
                  [class.is-invalid]="immunizations.at(i).get('administered_on').invalid && (immunizations.at(i).get('administered_on').dirty)" max="9999-12-31"/>
              </div>
              <div class="col">
                <small class="text-muted">Administered Facility</small>
                <input type="text" class="form-control" formControlName="administered_facility" />
              </div>
              <div class="col">
                <small class="text-muted">Next Dose On</small>
                <input type="date" class="form-control" formControlName="next_dose_on" max="9999-12-31"/>
              </div>
              <div class="col">
                <small class="text-muted">Notes</small>
                <input type="text" class="form-control" formControlName="notes" />
              </div>
            </div>
            <div class="text-right mt-3">
              <button type="button" class="btn btn-danger" (click)="remove(i)"
                *ngIf="i < immunizations?.controls?.length - 1"><i class="fas fa-minus mr-2"></i>
                Remove</button>
              <button type="button" class="btn btn-primary" (click)="add()"
                *ngIf="i === immunizations?.controls?.length - 1"><i class="fas fa-plus mr-2"></i>
                Add</button>
            </div>
          </div>
        </div>
        <div class="text-right mt-3" *ngIf="editMode">
          <button type="button" class="btn btn-primary mr-2" (click)="save()" [disabled]="form?.invalid">Save</button>
          <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
        </div>
      </div>
    </div>
  </fieldset>
</form>
