import { Component, OnInit, EventEmitter, Output} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormService } from 'src/app/utils/services/forms/forms.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-clinical-send-email',
  templateUrl: './clinical-send-email.component.html',
  styleUrls: ['./clinical-send-email.component.scss']
})
export class ClinicalSendEmailComponent implements OnInit {

  
  @Output() doctor: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedDocument: EventEmitter<any> = new EventEmitter<any>();

  form: any;
  modal = false;
  document: any;
  files: any;

  constructor(
    private fb: FormBuilder,
    private service: FormService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    
  }

  createForm() {
    this.form = this.fb.group({
      email: [null, [Validators.required]],
      name: [null, [Validators.required]],
    });
  }

  send(document){
    this.doctor.emit(this.form);
    this.selectedDocument.emit(document);
    this.modal = false;
  }

  onClose(){
    this.modal = false;
  }

  onOpen(document){
    this.modal = true;
    this.createForm();
    this.document = document;
  }

  getControl(name) {
    return this.form.get(name);
  }
}
