import {
  Component,
  OnInit,
  AfterViewInit,
  Renderer2,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';
import { TOKEN, CURRENT_USER } from 'src/app/utils/items/storage-names';
import { ApiService } from 'src/app/utils/services/api.service';
import { getStorage } from 'src/app/utils/helper/storage';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { AppService } from 'src/app/utils/services/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {

  @ViewChild('mainSidebar', { static: false }) mainSidebar;

  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();

  processing = false;
  user: any;
  subscription: any;

  constructor(
    private service: ApiService,
    public _appService: AppService,
    private router: Router,
    private doctorService: DoctorService,
  ) {}

  ngOnInit() {
    this.user = getStorage(CURRENT_USER, true);
    this.subscription = this.user?.active_subscription;
    this.getDoctor();
  }

  async getDoctor() {
    this.doctorService.getDoctorByUser(this.user?.id, {includes: 'prc_license,hmo,hospital_affiliations.hospital_master,clinics.resource.services.service_item,medical_associations'})
    .subscribe((response: any) => {
      this.user.doctor = response;
    });
  }

  ngAfterViewInit() {
    this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);
  }

  isCurrentPath(homeName) {
    const paths = window?.location?.pathname?.split('/')?.filter((x) => x);
    // return paths?.indexOf(homeName) > -1
    //   ? true
    //   : false;
    return paths[0] === homeName;
  }

  isCurrentSubpath(pathName: string): boolean {
    return window?.location?.pathname?.split('/')?.filter((x) => x)[1] === pathName;
  }

  switchToMwellBasic() {
    let url = environment.mwell_basic;

    //if (url.indexOf('platform') === -1) {
      url += '/onboard/dashboard';
    //}

    location.href = url;
  }

  logout() {
    if(this.processing) {
      return;
    }
    this.processing = true;

    this.service.logout()
    .subscribe((response: any) => {
      this.router.navigate(['/logout']);
    },(err) => {
      this.processing = false;
    });
  }
}
