<div class="main container">
  <div class="row">
    <div class="d-flex gap-sm align-items-center cursor-pointer pb-5 ml-1 mt-1" (click)="onRefresh()">
      <span class="sub-header">
        <i class="fas fa-long-arrow-alt-left fa-lg border rounded p-2 mr-1" style="color: #000000;"></i>
        Back
      </span>
    </div>
    <div class="col-sm-12">
      <h1 class="descr">
        Introducing the most advanced Electronic Medical Records (EMR),
        clinic management system designed by doctors for doctors <br />
        mWellMD Advanced
      </h1>
    </div>

    <div class="d-flex justify-content-center flex-grow-1 gap py-5 flex-wrap main-feat-container">
      <div class="d-flex align-items-center gap-sm main-feat">
        <svg width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="27.5" cy="28" r="27.5" fill="#EEF5FF" />
          <g clip-path="url(#clip0_6321_394)">
            <path
              d="M36.75 37H17.25C16.8281 37 16.5 37.375 16.5 37.75V39.25C16.5 39.6719 16.8281 40 17.25 40H36.75C37.125 40 37.5 39.6719 37.5 39.25V37.75C37.5 37.375 37.125 37 36.75 37ZM39.75 22C38.4844 22 37.5 23.0312 37.5 24.25C37.5 24.625 37.5469 24.9062 37.6875 25.1875L34.3125 27.25C33.5625 27.6719 32.625 27.4375 32.2031 26.6875L28.4062 19.9844C28.9219 19.6094 29.25 18.9531 29.25 18.25C29.25 17.0312 28.2188 16 27 16C25.7344 16 24.75 17.0312 24.75 18.25C24.75 18.9531 25.0781 19.6094 25.5469 19.9844L21.75 26.6875C21.3281 27.4375 20.3906 27.6719 19.6875 27.25L16.2656 25.1875C16.4062 24.9062 16.5 24.625 16.5 24.25C16.5 23.0312 15.4688 22 14.25 22C12.9844 22 12 23.0312 12 24.25C12 25.5156 12.9844 26.5 14.25 26.5C14.3438 26.5 14.4844 26.5 14.5781 26.5L18 35.5H36L39.375 26.5C39.4688 26.5 39.6094 26.5 39.75 26.5C40.9688 26.5 42 25.5156 42 24.25C42 23.0312 40.9688 22 39.75 22Z"
              fill="#035099" />
          </g>
          <defs>
            <clipPath id="clip0_6321_394">
              <rect width="50" height="30" fill="white" transform="translate(3 13)" />
            </clipPath>
          </defs>
        </svg>

        <span class="sub-header">
          Unlimited <br />
          storage capacity
        </span>
      </div>

      <div class="d-flex align-items-center gap-sm main-feat">
        <svg width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="27.5" cy="28" r="27.5" fill="#EEF5FF" />
          <path
            d="M27.25 28C31.0781 28 34.25 24.8828 34.25 21C34.25 17.1719 31.0781 14 27.25 14C23.3672 14 20.25 17.1719 20.25 21C20.25 24.8828 23.3672 28 27.25 28ZM20.6875 37.1875C20.6875 37.9531 21.2344 38.5 22 38.5C22.7109 38.5 23.3125 37.9531 23.3125 37.1875C23.3125 36.4766 22.7109 35.875 22 35.875C21.2344 35.875 20.6875 36.4766 20.6875 37.1875ZM32.5 29.8047V32.4844C34.4688 32.8672 36 34.6719 36 36.75V39.0469C36 39.4844 35.6719 39.8125 35.2891 39.9219L33.4844 40.25C33.2656 40.3047 33.0469 40.1406 32.9922 39.9219L32.8281 39.0469C32.7734 38.8281 32.9375 38.6094 33.1562 38.5547L34.25 38.3359V36.75C34.25 33.3594 29 33.1953 29 36.8594V38.3359L30.0391 38.5547C30.2578 38.6094 30.4219 38.8281 30.3672 39.0469L30.2031 39.9219C30.1484 40.1406 29.9297 40.3047 29.7109 40.25L27.9609 40.0312C27.5781 39.9766 27.25 39.5938 27.25 39.1562V36.75C27.25 34.6719 28.7266 32.9219 30.75 32.4844V30.0234C30.5859 30.0781 30.4766 30.0781 30.3672 30.1328C29.3828 30.4609 28.3438 30.6797 27.25 30.6797C26.1562 30.6797 25.0625 30.4609 24.0781 30.1328C23.6953 29.9688 23.2578 29.9141 22.875 29.8594V34.2891C24.1328 34.6719 25.0625 35.8203 25.0625 37.2422C25.0625 38.8828 23.6953 40.3047 22 40.3047C20.3047 40.3047 18.9375 38.8828 18.9375 37.2422C18.9375 35.8203 19.8672 34.6719 21.125 34.2891V29.9141C17.625 30.4609 15 33.4688 15 37.1328V39.5938C15 40.9062 16.0938 42 17.4062 42H37.0391C38.3516 42 39.5 40.9062 39.5 39.5938V37.1328C39.5 33.1953 36.3828 30.0234 32.5 29.8047Z"
            fill="#035099" />
        </svg>

        <span class="sub-header">
          Unlimited patient <br />
          consultation and records
        </span>
      </div>

      <div class="d-flex align-items-center gap-sm main-feat">
        <svg width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="27.5" cy="28" r="27.5" fill="#EEF5FF" />
          <path
            d="M27.5 20.8906L17.2344 29.9375C17.1406 30.0312 17.0469 30.0312 17 30.125V38.75C17 39.1719 17.3281 39.5 17.75 39.5H37.25C37.625 39.5 38 39.1719 38 38.75V30.125C37.9062 30.0312 37.8125 30.0312 37.7188 29.9375L27.5 20.8906ZM32 33.125C32 33.3594 31.8125 33.5 31.625 33.5H29V36.125C29 36.3594 28.8125 36.5 28.625 36.5H26.375C26.1406 36.5 26 36.3594 26 36.125V33.5H23.375C23.1406 33.5 23 33.3594 23 33.125V30.875C23 30.6875 23.1406 30.5 23.375 30.5H26V27.875C26 27.6875 26.1406 27.5 26.375 27.5H28.625C28.8125 27.5 29 27.6875 29 27.875V30.5H31.625C31.8125 30.5 32 30.6875 32 30.875V33.125ZM40.7188 26.6094L28.7188 16.0156C28.4375 15.7344 27.875 15.5 27.5 15.5C27.0781 15.5 26.5156 15.7344 26.2344 16.0156L14.2344 26.6094C14.0938 26.7031 13.9531 26.9844 13.9531 27.1719C13.9531 27.3125 14.0469 27.5469 14.1875 27.6406L15.1719 28.7656C15.3125 28.9062 15.5469 29.0469 15.7344 29.0469C15.875 29.0469 16.1094 28.9531 16.25 28.8125L26.9844 19.3438C27.0781 19.25 27.3125 19.1562 27.5 19.1562C27.6406 19.1562 27.875 19.25 27.9688 19.3438L38.7031 28.8125C38.8438 28.9531 39.0781 29 39.2188 29C39.4062 29 39.6406 28.9062 39.7812 28.7656L40.7656 27.6406C40.9062 27.5469 41 27.3125 41 27.1719C41 26.9844 40.8594 26.7031 40.7188 26.6094Z"
            fill="#035099" />
        </svg>

        <span class="sub-header">
          Up to 5 clinics and <br />
          5 staff accounts
        </span>
      </div>
    </div>

    <div class="row justify-content-around flex-grow-1 sub-features">
      <div class="col-sm-auto">
        <div class="d-flex align-items-center gap-sm">
          <i class="fas fa-check-circle" style="color: #39bd56"></i>
          Digitized patient, medical record, history, and monitoring
        </div>
        <div class="d-flex align-items-center gap-sm">
          <i class="fas fa-check-circle" style="color: #39bd56"></i>
          Appointment booking for in-person clinics
        </div>
        <div class="d-flex align-items-center gap-sm">
          <i class="fas fa-check-circle" style="color: #39bd56"></i>
          In-patients tracking
        </div>
        <div class="d-flex align-items-center gap-sm">
          <i class="fas fa-check-circle" style="color: #39bd56"></i>
          Upload important images and documents
        </div>
        <div class="d-flex align-items-center gap-sm">
          <i class="fas fa-check-circle" style="color: #39bd56"></i>
          Customized form templates
        </div>
      </div>

      <div class="col-sm-auto">
        <div class="d-flex align-items-center gap-sm">
          <i class="fas fa-check-circle" style="color: #39bd56"></i>
          Patient Referrals
        </div>
        <div class="d-flex align-items-center gap-sm">
          <i class="fas fa-check-circle" style="color: #39bd56"></i>
          Manage staff accounts
        </div>
        <div class="d-flex align-items-center gap-sm">
          <i class="fas fa-check-circle" style="color: #39bd56"></i>
          Monitor billing
        </div>
        <div class="d-flex align-items-center gap-sm">
          <i class="fas fa-check-circle" style="color: #39bd56"></i>
          Basic analytics and dashboards
        </div>
        <div class="d-flex align-items-center gap-sm">
          <i class="fas fa-check-circle" style="color: #39bd56"></i>
          Responsive tech support
        </div>
      </div>
    </div>

    <div class="col-sm-12 text-center py-sm-5 py-4">
      <button class="btn btn-primary px-5 py-3 free-trial" [disabled]="loading" (click)="onCreateFreeSubscription()">
        <i *ngIf="loading" class="ml-2 fas fa-circle-notch spinning"></i>
        Start your 60-day FREE Trial
      </button>
      <div class="col-sm-12 pt-2">
        <strong>Absolutely FREE,</strong> cancel anytime
      </div>
    </div>

    <div class="col-sm-12 text-center">
      <h5>You can choose from two plans:</h5>
    </div>

    <div class="col-sm-12 text-center">
      <div class="price-container d-flex justify-content-center gap mb-5">
        <div class="plan" (click)="setSelectedPlan('mwell-executive-md-subscription')">
          <div class="stars">
            <svg width="46" height="50" viewBox="0 0 46 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.5"
                d="M21.0284 9.6613C20.7417 8.71432 19.4009 8.71432 19.1142 9.6613L17.772 14.0946C17.6884 14.3707 17.4897 14.5972 17.227 14.716L13.0859 16.5889C12.302 16.9434 12.302 18.0566 13.0859 18.4111L17.227 20.284C17.4897 20.4028 17.6884 20.6293 17.772 20.9054L19.1142 25.3387C19.4009 26.2857 20.7417 26.2857 21.0284 25.3387L22.3706 20.9054C22.4542 20.6293 22.6528 20.4028 22.9156 20.284L27.0566 18.4111C27.8405 18.0566 27.8405 16.9434 27.0566 16.5889L22.9156 14.716C22.6528 14.5972 22.4542 14.3707 22.3706 14.0946L21.0284 9.6613Z"
                fill="#31C5F4" />
              <path opacity="0.2"
                d="M4.15287 2.62319C3.97324 2.00534 3.09791 2.00534 2.91828 2.62319L2.66159 3.50606C2.6101 3.68315 2.48504 3.8296 2.31819 3.90818L1.23478 4.41841C0.742837 4.65009 0.742838 5.34991 1.23478 5.58159L2.31819 6.09182C2.48504 6.1704 2.6101 6.31685 2.66159 6.49394L2.91828 7.37681C3.09791 7.99466 3.97324 7.99466 4.15287 7.37681L4.40956 6.49394C4.46105 6.31685 4.58611 6.1704 4.75296 6.09182L5.83637 5.58159C6.32831 5.34991 6.32831 4.65009 5.83637 4.41841L4.75296 3.90818C4.58611 3.8296 4.46105 3.68315 4.40956 3.50606L4.15287 2.62319Z"
                fill="#31C5F4" />
              <path opacity="0.2"
                d="M38.5507 9.11956C38.4094 8.64213 37.7332 8.64213 37.5919 9.11956L37.0551 10.9326C37.0143 11.0705 36.9161 11.1841 36.7856 11.2445L35.0524 12.0462C34.6656 12.2251 34.6656 12.7749 35.0524 12.9538L36.7856 13.7555C36.9161 13.8159 37.0143 13.9295 37.0551 14.0674L37.5919 15.8804C37.7332 16.3579 38.4094 16.3579 38.5507 15.8804L39.0874 14.0674C39.1283 13.9295 39.2265 13.8159 39.357 13.7555L41.0902 12.9538C41.477 12.7749 41.477 12.2251 41.0902 12.0462L39.357 11.2445C39.2265 11.1841 39.1283 11.0705 39.0874 10.9326L38.5507 9.11956Z"
                fill="#31C5F4" />
              <path opacity="0.2"
                d="M33.4602 28.8027C33.1808 27.8416 31.8192 27.8416 31.5398 28.8027L31.1405 30.1761C31.0604 30.4516 30.8658 30.6794 30.6063 30.8016L28.921 31.5953C28.1557 31.9557 28.1557 33.0443 28.921 33.4047L30.6063 34.1984C30.8658 34.3206 31.0604 34.5484 31.1405 34.8239L31.5398 36.1973C31.8192 37.1584 33.1808 37.1584 33.4602 36.1973L33.8595 34.8239C33.9396 34.5484 34.1342 34.3206 34.3937 34.1984L36.079 33.4047C36.8443 33.0443 36.8443 31.9557 36.079 31.5953L34.3937 30.8016C34.1342 30.6794 33.9396 30.4516 33.8595 30.1761L33.4602 28.8027Z"
                fill="#31C5F4" />
              <path opacity="0.2"
                d="M42.4794 41.1196C42.3381 40.6421 41.6619 40.6421 41.5206 41.1196L40.9838 42.9326C40.943 43.0705 40.8448 43.1841 40.7143 43.2445L38.9811 44.0462C38.5943 44.2251 38.5943 44.7749 38.9811 44.9538L40.7143 45.7555C40.8448 45.8159 40.943 45.9295 40.9838 46.0674L41.5206 47.8804C41.6619 48.3579 42.3381 48.3579 42.4794 47.8804L43.0162 46.0674C43.057 45.9295 43.1552 45.8159 43.2857 45.7555L45.0189 44.9538C45.4057 44.7749 45.4057 44.2251 45.0189 44.0462L43.2857 43.2445C43.1552 43.1841 43.057 43.0705 43.0162 42.9326L42.4794 41.1196Z"
                fill="#31C5F4" />
            </svg>
          </div>
          <div class="plan-body">
            <span class="title">Monthly Plan</span>

            <div class="price-body">
              <span class="price">PHP 1,900</span>
              <span class="price-info">/month</span>
            </div>
            <span class="info">60-day free trial on first use</span>
          </div>
        </div>

        <div class="plan" (click)="setSelectedPlan('mwell-executive-md-subscription-annual')">
          <div class="stars">
            <svg width="46" height="50" viewBox="0 0 46 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.5"
                d="M21.0284 9.6613C20.7417 8.71432 19.4009 8.71432 19.1142 9.6613L17.772 14.0946C17.6884 14.3707 17.4897 14.5972 17.227 14.716L13.0859 16.5889C12.302 16.9434 12.302 18.0566 13.0859 18.4111L17.227 20.284C17.4897 20.4028 17.6884 20.6293 17.772 20.9054L19.1142 25.3387C19.4009 26.2857 20.7417 26.2857 21.0284 25.3387L22.3706 20.9054C22.4542 20.6293 22.6528 20.4028 22.9156 20.284L27.0566 18.4111C27.8405 18.0566 27.8405 16.9434 27.0566 16.5889L22.9156 14.716C22.6528 14.5972 22.4542 14.3707 22.3706 14.0946L21.0284 9.6613Z"
                fill="#31C5F4" />
              <path opacity="0.2"
                d="M4.15287 2.62319C3.97324 2.00534 3.09791 2.00534 2.91828 2.62319L2.66159 3.50606C2.6101 3.68315 2.48504 3.8296 2.31819 3.90818L1.23478 4.41841C0.742837 4.65009 0.742838 5.34991 1.23478 5.58159L2.31819 6.09182C2.48504 6.1704 2.6101 6.31685 2.66159 6.49394L2.91828 7.37681C3.09791 7.99466 3.97324 7.99466 4.15287 7.37681L4.40956 6.49394C4.46105 6.31685 4.58611 6.1704 4.75296 6.09182L5.83637 5.58159C6.32831 5.34991 6.32831 4.65009 5.83637 4.41841L4.75296 3.90818C4.58611 3.8296 4.46105 3.68315 4.40956 3.50606L4.15287 2.62319Z"
                fill="#31C5F4" />
              <path opacity="0.2"
                d="M38.5507 9.11956C38.4094 8.64213 37.7332 8.64213 37.5919 9.11956L37.0551 10.9326C37.0143 11.0705 36.9161 11.1841 36.7856 11.2445L35.0524 12.0462C34.6656 12.2251 34.6656 12.7749 35.0524 12.9538L36.7856 13.7555C36.9161 13.8159 37.0143 13.9295 37.0551 14.0674L37.5919 15.8804C37.7332 16.3579 38.4094 16.3579 38.5507 15.8804L39.0874 14.0674C39.1283 13.9295 39.2265 13.8159 39.357 13.7555L41.0902 12.9538C41.477 12.7749 41.477 12.2251 41.0902 12.0462L39.357 11.2445C39.2265 11.1841 39.1283 11.0705 39.0874 10.9326L38.5507 9.11956Z"
                fill="#31C5F4" />
              <path opacity="0.2"
                d="M33.4602 28.8027C33.1808 27.8416 31.8192 27.8416 31.5398 28.8027L31.1405 30.1761C31.0604 30.4516 30.8658 30.6794 30.6063 30.8016L28.921 31.5953C28.1557 31.9557 28.1557 33.0443 28.921 33.4047L30.6063 34.1984C30.8658 34.3206 31.0604 34.5484 31.1405 34.8239L31.5398 36.1973C31.8192 37.1584 33.1808 37.1584 33.4602 36.1973L33.8595 34.8239C33.9396 34.5484 34.1342 34.3206 34.3937 34.1984L36.079 33.4047C36.8443 33.0443 36.8443 31.9557 36.079 31.5953L34.3937 30.8016C34.1342 30.6794 33.9396 30.4516 33.8595 30.1761L33.4602 28.8027Z"
                fill="#31C5F4" />
              <path opacity="0.2"
                d="M42.4794 41.1196C42.3381 40.6421 41.6619 40.6421 41.5206 41.1196L40.9838 42.9326C40.943 43.0705 40.8448 43.1841 40.7143 43.2445L38.9811 44.0462C38.5943 44.2251 38.5943 44.7749 38.9811 44.9538L40.7143 45.7555C40.8448 45.8159 40.943 45.9295 40.9838 46.0674L41.5206 47.8804C41.6619 48.3579 42.3381 48.3579 42.4794 47.8804L43.0162 46.0674C43.057 45.9295 43.1552 45.8159 43.2857 45.7555L45.0189 44.9538C45.4057 44.7749 45.4057 44.2251 45.0189 44.0462L43.2857 43.2445C43.1552 43.1841 43.057 43.0705 43.0162 42.9326L42.4794 41.1196Z"
                fill="#31C5F4" />
            </svg>
          </div>
          <div class="ribbon">Free 1 month<br />Save Php 1,900</div>
          <div class="plan-body">
            <span class="title">Annual Plan</span>
            <div class="price-body">
              <span class="price">PHP 22,800</span>
              <span class="price-info">/year</span>
            </div>
            <span class="info"> 12 months + 1 month FREE </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog [(visible)]="successModal" [modal]="true" [closable]="false" [draggable]="false" [resizable]="false"
  [style]="{maxWidth: '350px'}">
  <div class="d-flex flex-column align-items-center justify-content-center" style="gap: 8px">
    <img src="assets/img/popper.png" alt="popper" class="img-fluid icon" />
    <img src="assets/img/logo.png" alt="logo" class="logo img-fluid" />
    <h2 class="header">Congratulations!</h2>
    <p class="info">
      Your mWellMD Advanced 60-days FREE trial starts now!
    </p>
    <button class="btn btn-primary modal-btn" (click)="onRefresh()">
      Continue
    </button>
  </div>
</p-dialog>

<p-dialog [(visible)]="paymentModal" [modal]="true" [closable]="true" [draggable]="false" [resizable]="false"
  [style]="{width: '95vw'}">
  <div class="p-4" #subscription>
    <div class="container-fluid pt-2">
      <div class="row">
        <div class="col-sm-12 col-md-7">
          <div class="card-template">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <p class="card-title">Payment Information</p>
                </div>

                <div class="d-flex flex-wrap flex-grow-1" *ngIf="cards?.length > 0">
                  <div class="col-12 mt-3 pb-3">Select Existing Card</div>

                  <div class="col-sm-12 col-md-4 cursor-pointer" *ngFor="let card of cards; let i = index"
                    (click)="setSelectedCard(card.cardTokenId)">
                    <div class="card" [ngClass]="
                        card.cardTokenId == selectedCard ? 'border-0' : ''
                      ">
                      <div [ngClass]="
                          card.cardTokenId == selectedCard
                            ? 'card-selected'
                            : 'card-unselected'
                        " style="gap: 16px">
                        <div class="d-flex align-items-center" style="gap: 8px">
                          <div class="d-flex align-items-center">
                            <input type="radio" class="form-check-input" [id]="card.cardTokenId" name="exampleRadios"
                              [value]="card.cardTokenId" [checked]="card.cardTokenId == selectedCard" />
                            <label class="form-check-label" [for]="card.cardTokenId">
                              {{ card.cardType | titlecase }}
                            </label>
                          </div>

                          <span class="badge" [ngClass]="
                              card.state === 'VERIFIED'
                                ? 'badge-success'
                                : 'badge-danger'
                            ">
                            {{ card.state }}
                            <a *ngIf="card.state !== 'VERIFIED'" [href]="card.verification_url" target="_blank" rel="noopener noreferrer" class="text-white">verify here</a>
                          </span>
                        </div>

                        <span class="cc-number">
                          XXXX XXXX XXXX {{ card.last4 }}
                        </span>

                        <div class="d-flex" style="gap: 16px">
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="breakline">
                    <span>OR</span>
                  </div>
                </div>

                <div class="col-12">
                  <form [formGroup]="form" (ngSubmit)="onSubmitCard()">
                    <p>Add New Card</p>
                    <div class="form-wrapper row pt-2">
                      <div class="col-4">
                        <small>Card Number <b class="text-danger">*</b></small>
                        <input type="text" maxlength="19" numbersOnly class="form-control" formControlName="number"
                          [class.is-invalid]="
                            !form.get('number').valid &&
                            form.get('number').touched
                          " />
                        <div class="invalid-feedback" *ngIf="
                            form.get('number').hasError('required') &&
                            form.get('number').touched
                          ">
                          Please input your card number.
                        </div>
                      </div>
                      <div class="col-3 px-2 date-control">
                        <small>Expiration (mm/yy)
                          <b class="text-danger">*</b></small>
                        <select name="expiration" id="expiration" class="custom-select" #expiration
                          (change)="setMonthAndYearExpiration(expiration.value)">
                          <option [value]="date" *ngFor="let date of expirationList">
                            {{ date }}
                          </option>
                        </select>
                      </div>

                      <div class="col-2">
                        <small>CVV/CVC <b class="text-danger">*</b></small>
                        <input type="text" maxlength="4" numbersOnly class="form-control" formControlName="cvc"
                          [class.is-invalid]="
                            !form.get('cvc').valid && form.get('cvc').touched
                          " />
                        <div class="invalid-feedback" *ngIf="
                            form.get('cvc').hasError('required') &&
                            form.get('cvc').touched
                          ">
                          Input required.
                        </div>
                      </div>
                      <div class="col pt-4">
                        <button type="submit" class="btn btn-primary btn-block" [disabled]="form.disabled">
                          <i *ngIf="form.disabled" class="ml-2 fas fa-circle-notch spinning px-2"></i>
                          <strong><i class="fas fa-plus pr-2"></i>Add card</strong>
                        </button>
                        <p *ngIf="cardErrmsg != null" class="text-danger">
                          {{ cardErrmsg }}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-5">
          <div class="card-template">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <p class="card-title">Review Your Subscription</p>
                </div>

                <div class="col-12">
                  <div class="card rounded light-blue-backdrop border-0">
                    <div class="card-body">
                      <img src="assets/img/mwell-md-logo-hires.png" alt="mwell-md-logo" class="img-fluid" />

                      <div class="d-flex flex-column pt-3">
                        <p>
                          <i class="fas fa-check text-success pr-2"></i>
                          Unlimited number Patients
                        </p>
                        <p>
                          <i class="fas fa-check text-success pr-2"></i>
                          Unlimited GB of Storage
                        </p>
                        <p>
                          <i class="fas fa-check text-success pr-2"></i>
                          5 Clinics, 3 Staff Account, 5 Doctors
                        </p>
                      </div>

                      <div class="d-flex flex-row-reverse">
                        <div class="d-flex flex-column align-items-end">
                          <small class="mb-0 amount-due">Total Amount Due</small>
                          <h1 class="mb-0 amount">PHP {{price}}</h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex flex-row-reverse">
                    <div class="d-flex flex-column align-items-end">
                      <p class="mb-0">PHP {{price}} / month</p>
                      <button class="btn btn-primary px-4" [disabled]="loading || !selectedCard"
                        (click)="finalizePayment()">
                        <i *ngIf="loading" class="ml-2 fas fa-circle-notch spinning"></i>
                        Subscribe to mWell MD
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
