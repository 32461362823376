import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinical-document-uploader',
  templateUrl: './clinical-document-uploader.component.html',
  styleUrls: ['./clinical-document-uploader.component.scss']
})
export class ClinicalDocumentUploaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
