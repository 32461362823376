import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { suffix } from 'src/app/utils/items/suffix';

@Component({
  selector: 'app-emergency-contacts',
  templateUrl: './emergency-contacts.component.html',
  styleUrls: ['./emergency-contacts.component.scss']
})
export class EmergencyContactsComponent implements OnInit {
  @Input()
  patientForm: FormGroup;

  @Input()
  disableFields = false;

  @Input()
  patient: any;

  @Input()
  validations: any;

  relationships: any;
  purposes: any;
  suffix: any;

  constructor() {}

  ngOnInit(): void {
    this.relationships = this.getMasterByName('Relationship');
    this.purposes = this.getMasterByName('Purpose of Contact');
    this.suffix = suffix;
  }

  isRequired(rules: string) {
    return rules ? rules.includes('required') : false;
  }

  getControl(control) {
    return this.patientForm.get(control);
  }

  getMasterByName(name: string) {
    return JSON.parse(localStorage.getItem('value_master')).find((item) => {
      return item?.text === name;
    });
  }

  checkContactNumber(control) {
    const validations = ['minLength:10','maxLength:10','pattern:[0-9]{10}'];

    const validator = validations.map((item) => {
      const items = item.split(':');

      return items?.length > 1
        ? Validators[items[0]](items[1])
        : Validators[items[0]];
    });

    this.patientForm?.controls?.emergency_contact?.get(control).setValidators(validator);
    this.patientForm?.controls?.emergency_contact?.get(control).updateValueAndValidity();
  }
}
