<p-dialog [baseZIndex]="2000" [style]="{ minWidth: '100%', maxHeight: '100%', height: '100%' }" [(visible)]="modal"
    [closable]="true" [closeOnEscape]="true" [dismissableMask]="true" [modal]="true" [position]="'center'"
    [draggable]="false">
    <ng-template pTemplate="header">
        &nbsp; <span class="title">{{ file?.title }}</span>
    </ng-template>

    <div class="d-flex flex-row h-100 w-100">
        <div *ngIf="file != null && file.url != null" class="pdf-container d-flex flex-row h-100 w-100">
            <div *ngIf="files.length > 1" class="controls-container left d-flex align-items-center">
                <button _ngcontent-nqh-c96="" pbutton="" pripple="" type="button" icon="pi pi-angle-left"
                    class="p-element p-ripple p-button-rounded p-button p-component p-button-icon-only"
                    (click)="prev()"><span class="p-button-icon pi pi-angle-left" aria-hidden="true"></span><span
                        class="p-ink"></span></button>
            </div>
            <div *ngIf="file.type?.indexOf('pdf') > -1" class="pdf-viewer h-100 w-100"
                [style.background-image]="showSpinner()">
                <object class="h-100 w-100" [data]="file.sanitizedUrl" type="application/pdf"
                    [style.display]="{ 'none': loading }" (load)="onLoad('fromObject')">
                    <iframe [src]="file.googleDocPDFUrl" (load)="onLoad('fromIframe')"></iframe>
                </object>
            </div>
            <div *ngIf="file.type?.indexOf('image') > -1"
                class="pdf-viewer h-100 w-100 d-flex justify-content-center align-items-center"
                [style.background-image]="showSpinner()">
                <img [src]="file.url" (load)="onLoad('fromBG')" [style.display]="{ 'none': loading }"
                    draggable="false" />
            </div>

            <div *ngIf="files.length > 1" class="controls-container right d-flex align-items-center">
                <button _ngcontent-nqh-c96="" pbutton="" pripple="" type="button" icon="pi pi-angle-right"
                    class="p-element p-ripple p-button-rounded p-button p-component p-button-icon-only"
                    (click)="next()"><span class="p-button-icon pi pi-angle-right" aria-hidden="true"></span><span
                        class="p-ink"></span></button>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <span *ngIf="currentIndex > -1" class="page d-flex justify-content-center">{{ currentIndex + 1 }} of {{
            files?.length }}</span>
    </ng-template>
</p-dialog>