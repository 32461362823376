<p-dialog [baseZIndex]="2000" [style]="{ minWidth: '600px', marginTop: '30px'  }" [(visible)]="modal" [closable]="false"
    [closeOnEscape]="false" [dismissableMask]="false" [modal]="true" [position]="'center'" [draggable]="false">
    <fieldset [disabled]="processing">
        <div class="pr-3 pl-3">
            <h3 class="text-primary bold">{{ editMode ? 'Edit Inpatient' : 'Add Inpatient' }} </h3>
            <div class="form-group mt-2">
                <small class="text-muted">Search Patient <b *ngIf="!patient" class="ml-2 text-danger">*</b></small>
                <app-patient-autocomplete (select)="setPatient($event)" (unselect)="clearPatient()"
                    (clear)="clearPatient()" #patientComp></app-patient-autocomplete>
            </div>
            <div class="form-group">
                <small class="text-muted">
                    <i class="fas fa-info-circle cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top"
                        title="List of hospitals is based on affiliated hospital declared in My Profile > Credentials"></i>
                    Hospital
                </small>
                <select (change)="selectedHospital($event)" class="form-control"
                    [(ngModel)]="health_facility_code_short">
                    <option [ngValue]="" selected>{{ hospitalNotSpecified }}</option>
                    <option [ngValue]="hospital.health_facility_code_short"
                        *ngFor="let hospital of hospitalAffiliations()">{{ hospital?.hospital_master?.facility_name }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <small class="text-muted">Room/Location</small>
                <input type="text" class="form-control" [(ngModel)]="location_room">
            </div>
            <div class="form-group">
                <small class="text-muted">HMO</small>

                <select class="form-control" [(ngModel)]="hmo_code">
                    <option [value]="null" selected>HMO not specified</option>
                    <option [value]="hmo.hmo_code" *ngFor="let hmo of hmo_masters">{{ hmo?.hmo_text }}</option>
                </select>

            </div>
            <div class="form-group">
                <small class="text-muted">Reason for Admission </small>
                <input type="text" class="form-control" [(ngModel)]="complaint">
            </div>
            <div class="form-group">
                <small class="text-muted">Attending Physician (if not self)</small>
                <input type="text" class="form-control" [(ngModel)]="attending_physician">
            </div>
            <div class="form-group">
                <small class="text-muted">Date of Admission<b class="ml-2 text-danger"
                        *ngIf="!admission_datetime">*</b></small>
                <input type="datetime-local" class="form-control" [(ngModel)]="admission_datetime">
            </div>
            <div *ngIf="discharge_datetime != null">
                <div class="form-group" *ngIf="editMode">
                    <small class="text-muted">Date of Discharge</small>
                    <input type="datetime-local" class="form-control" [(ngModel)]="discharge_datetime">
                </div>
                <div class="form-group" *ngIf="editMode">
                    <small class="text-muted">Discharge Remarks </small>
                    <input type="text" class="form-control" [(ngModel)]="discharge_remarks">
                </div>
            </div>
        </div>
    </fieldset>
    <p-footer>
        <div class="w-100 text-right pr-3 pl-3 pb-3">
            <button role="button" class="btn btn-primary btn-lg mr-2" (click)="addAsInPatient()"
                [disabled]="processing || !patient ">
                {{ editMode ? 'Update' : 'Create' }}
                <i *ngIf="processing" class="ml-2 nav-icon fas fa-circle-notch spinning"></i>
            </button>
            <button role="button" class="btn btn-outline-primary btn-lg" (click)="onClose()" [disabled]="processing">
                Close
            </button>
        </div>
    </p-footer>
</p-dialog>
