import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { generateExpiration } from 'src/app/utils/helper/date';
import { AppService } from 'src/app/utils/services/app.service';
import { UserService } from 'src/app/utils/services/cas/users.service';

@Component({
  selector: 'app-payment-wall',
  templateUrl: './payment-wall.component.html',
  styleUrls: ['./payment-wall.component.scss'],
})
export class PaymentWallComponent implements OnInit, OnDestroy {
  successModal: boolean = false;
  paymentModal: boolean = false;

  user: any;
  selectedPlan: string;
  cards: any[];
  selectedCard: any;
  form: FormGroup;
  cardErrmsg: any;
  expirationList: any;
  loading: boolean = false;
  price: any;

  constructor(
    private renderer: Renderer2,
    private userService: UserService,
    private fb: FormBuilder,
    private _appService: AppService,
    private _toastr: ToastrService
  ) {
    this.user = this._appService.getCurrentUser();

    if (this.user?.active_subscription?.subscription_status_code === 'active') {
      window.location.href = '/home';
    }
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'payment-wall-container');
    this.getCustomerCards();
    this.expirationList = generateExpiration();
    this.form = this.fb.group({
      number: ['', [Validators.required]],
      expMonth: ['', [Validators.required]],
      expYear: ['', [Validators.required]],
      cvc: ['', [Validators.required]],
    });

    this.form.patchValue({
      expMonth: this.expirationList[0].split('/').shift(),
      expYear: this.expirationList[0].split('/').pop(),
    });
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'payment-wall-container');
  }

  setSelectedCard(val) {
    this.selectedCard = val;
  }

  setSelectedPlan(val) {
    this.selectedPlan = val;
    this.price = val === 'mwell-executive-md-subscription' ? '1,900.00' : '22,800.00';
    this.paymentModal = true;
  }

  getCustomerCards() {
    this.userService
      .getCustomerCards()
      .pipe(
        map((cards: any[]) =>
          cards.map((card) => ({
            ...card,
            cardType: card.cardType?.split('-')?.join(' '),
          }))
        )
      )
      .subscribe((response: any) => {
        this.cards = response;
      });
  }

  setMonthAndYearExpiration(val) {
    let month = val.split('/').shift(),
      year = val.split('/').pop();
    this.form.patchValue({
      expMonth: month,
      expYear: year,
    });
  }

  onSubmitCard() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
    } else {
      this.form.disable();
      this.form.markAsUntouched();
      this.cardErrmsg = null;

      this.userService
        .addCustomerCard({
          card: this.form.value,
          planCode: this.selectedPlan,
        })
        .subscribe(
          (response: any) => {
            if (response.cardTokenId != null) {
              this.cards.push(response);

              this._toastr.success(
                'You have successfully added a card. Please check your email for verification. Thanks.',
                'New Card Added!',
                {
                  timeOut: 15000,
                }
              );
            }

            this.form.enable();
            this.form.reset();
          },
          (err) => {
            console.warn('onSubmitCard error:', err);
            this.form.enable();
            this.form.reset();
            this.cardErrmsg = 'Invalid card or it is currently not supported.';
          }
        );
    }
  }

  finalizePayment() {
    let data = {
      cardTokenId: this.selectedCard,
      plan: this.selectedPlan,
    };

    this.loading = true;

    this.userService.finalizePayment(data).subscribe(
      (response: any) => {
        this.loading = false;

        if (response.success === true) {
          this._appService.updateUserSubscription(response.active_subscription);
          this.successModal = !this.successModal;
        } else {
          this._toastr.error('An error occured in the server.');
        }
      },
      (err) => {
        this.loading = false;
        this._toastr.error('An error occured in the server.');
      }
    );
  }

  onCreateFreeSubscription() {
    this.loading = true;

    this.userService.createFreeSubscription().subscribe(
      (response: any) => {
        this.loading = false;

        if (response.success === true) {
          this._appService.updateUserSubscription(response.active_subscription);
          this.successModal = !this.successModal;
        } else {
          this._toastr.error('An error occured in the server.');
        }
      },
      (err) => {
        this.loading = false;
        this._toastr.error('An error occured in the server.');
      }
    );
  }

  onRefresh() {
    window.location.href = '/home';
  }
}
