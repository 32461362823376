import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnInit {
  @Output() onConfirm: EventEmitter<any> = new EventEmitter<any>();

  @Input() title: string;
  @Input() message: string;
  @Input() processing: boolean;
  @Input() confirmText: string = 'Confirm';

  modal = false;

  constructor() { }

  ngOnInit(): void {
  }

  open() {
    this.modal = true;
  }

  close() {
    this.modal = false;
  }

  confirm() {
    this.onConfirm.emit();
  }
}
