<p-dialog
  [baseZIndex]="2000"
  [style]="{ minWidth: '600px', marginTop: '30px' }"
  [(visible)]="modal"
  [closable]="false"
  [closeOnEscape]="false"
  [dismissableMask]="false"
  [modal]="true"
  [position]="'center'"
  [draggable]="false"
>
  <fieldset>
    <div *ngIf="!loadingForm" class="pr-3 pl-3">
      <h3 class="text-primary bold">Edit Visit Details</h3>
      <div class="form-group mt-2 mb-2">
        <small class="text-muted">Patient</small>
        <div class="readonly-fields name">
          {{ visit?.patient?.display }}
        </div>
      </div>
      <div class="form-group">
        <small class="text-muted">
          Clinic <b *ngIf="!clinic" class="ml-2 text-danger">*</b></small
        >
        <!-- <select class="form-control" [(ngModel)]="clinic"
                    (change)="slot = null; service = null; autoSelectService(); getServiceSchedule()"> -->

        <select
          class="form-control"
          [(ngModel)]="clinic"
          (change)="clinicChange()"
        >
          <option value="null" hidden selected>Select Clinic</option>
          <option [ngValue]="clinic" *ngFor="let clinic of clinics">
            {{ clinic?.name_display }} ({{ clinic?.type_text }})
          </option>
        </select>
      </div>
      <div class="form-group">
        <small class="text-muted"
          >Service<b *ngIf="!service" class="ml-2 text-danger">*</b></small
        >
        <select class="form-control" [(ngModel)]="service" [disabled]="true">
          <option value="null" hidden selected>Select Service</option>
          <!-- <option [ngValue]="service" *ngFor="let service of clinic?.resource?.services">{{
                        service?.service_item?.service_item_name }}</option> -->
          <ng-container *ngFor="let service of clinic?.resource?.services">
            <option
              *ngIf="service.service_item_code === service?.service_item_code"
              [ngValue]="service"
            >
              {{ service?.service_item?.service_item_name }}
            </option>
          </ng-container>
        </select>
      </div>
      <div class="form-group">
        <small class="text-muted">Visit Start Date</small>
        <input
          type="datetime-local"
          class="form-control"
          [(ngModel)]="start_date"
          max="9999-12-31T23:59"
        />
      </div>
      <div class="form-group">
        <small class="text-muted"
          >Visit End Date
          <b class="ml-2 text-danger" *ngIf="!date">*</b></small
        >
        <input
          type="datetime-local"
          class="form-control"
          [(ngModel)]="end_date"
          max="9999-12-31T23:59"
        />
      </div>
      <div class="form-group">
        <small class="text-muted"
          >Reason for Consultation
          <b class="ml-2 text-danger" *ngIf="!complaint">*</b></small
        >
        <input
          type="text"
          class="form-control"
          [(ngModel)]="complaint"
          [disabled]="!service"
        />
      </div>
    </div>

    <div *ngIf="loadingForm" class="loading-form">
      <i class="ml-2 nav-icon fas fa-circle-notch spinning text-base"></i>
    </div>
  </fieldset>
  <p-footer>
    <div class="w-100 text-right pr-3 pl-3 pb-3">
      <button
        role="button"
        class="btn btn-primary btn-lg mr-2"
        (click)="updateVisit()"
        [disabled]="
          processing || !complaint
        "
      >
        <!-- <button role="button" class="btn btn-primary btn-lg mr-2" (click)="createAppointment()"> -->
        Update
        <i
          *ngIf="processing"
          class="ml-2 nav-icon fas fa-circle-notch spinning"
        ></i>
      </button>
      <button
        role="button"
        class="btn btn-outline-primary btn-lg"
        (click)="onClose()"
        [disabled]="processing"
      >
        Close
      </button>
    </div>
  </p-footer>
</p-dialog>
