export const environment = {
  production: true,
  cas_url: 'https://emr.mwellmd.com.ph/cas-api',
  cas_ver: '/api/v1',
  mpi_url: 'https://emr.mwellmd.com.ph/mpi-api',
  mpi_ver: '/api/v1',
  doctor_url: 'https://emr.mwellmd.com.ph/doctor-api',
  doctor_ver: '/api/v1',
  utilities_url: 'https://emr.mwellmd.com.ph/utilities-api',
  utilities_ver: '/api/v1',
  forms_url: 'https://emr.mwellmd.com.ph/forms-api',
  forms_ver: '/api/v1',
  schedule_url: 'https://emr.mwellmd.com.ph/schedule-api',
  schedule_ver: '/api/v1',
  acs_connection_string: 'endpoint=https://mp-coms-001.communication.azure.com/;accesskey=Q6/Z4xfLTr2yHAsQ8jfep8B7Nr6lehygP+/J4tT8H76An3I/wbzAnLcwOSY+Sqe971CXisJtgm0lT8LHg1bWOg==',

  // pusher info,
  pusher_app_cluster: 'ap1',
  pusher_app_id: '1410527',
  pusher_app_key: 'b6bd23aa92eb2e767cc0',
  pusher_app_secret: 'b5b8d70cd9845185deb4',

  //recaptcha
  recaptch_site_key: '6LfiwUcgAAAAAJV2iWfBzZFIK5HqYVSy_PpXtary',
  recaptcha_secret_key: '6LfiwUcgAAAAAEte6ZLGKjbepfQ3-iJYC6iL7qHc',

  // telemedicine credentials
  mwell_basic_api: '',
  mwell_telemend_url: 'https://gw.mwell.com.ph/api/v2/web/doctors_onboarding',
  mwell_telemend_subscription_key: '0a57846786b34b0a89328c39f584892b',
  mwell_telemend_key: 'mwellmd',
  mwell_telemend_secret: 'erHnF8YU2Lbt79tTBBvR',
  mwell_telemend_renew_time: 50,
  mwell_basic: 'https://platform.mwell.com.ph',
  mwellmd_emr: 'https://emr.mwellmd.com.ph',
  adb2c_auth_url: "https://login.mwell.com.ph/mwellb2c.onmicrosoft.com/B2C_1_Authentication_Doctor/oauth2/v2.0/authorize?client_id=17b708a0-431a-479e-85d9-1b6365562bae&redirect_uri=https://emr.mwellmd.com.ph&scope=17b708a0-431a-479e-85d9-1b6365562bae%20offline_access&response_type=code",
  // adb2c_auth_url: "https://mwellb2c.b2clogin.com/mwellb2c.onmicrosoft.com/B2C_1_Authentication_Doctor/oauth2/v2.0/authorize?client_id=17b708a0-431a-479e-85d9-1b6365562bae&redirect_uri=https://emr.mwellmd.com.ph&scope=17b708a0-431a-479e-85d9-1b6365562bae%20offline_access&response_type=code"
  // url added to logout to also logout adb2c
  adb2c_logout_url: '',
  pe_dev_api_url: '',
  
  top_dev_api_url: 'https://mwell-doctor-api-dev.topdevs.co',
  top_dev_x_api_key: '4a28f0177f63b2c9a20e2b9987e670d4',
};
