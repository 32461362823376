import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinical-document-create-modal',
  templateUrl: './clinical-document-create-modal.component.html',
  styleUrls: ['./clinical-document-create-modal.component.scss']
})
export class ClinicalDocumentCreateModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
