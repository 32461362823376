<p-dialog
  [baseZIndex]="2000"
  [style]="{ maxWidth: '80%', maxHeight: '80%', marginTop: '30px', width: '680px' , height: '575px'}"
  [(visible)]="modal"
  [closable]="false"
  [closeOnEscape]="false"
  [dismissableMask]="false"
  [modal]="true"
  [position]="'center'"
  [draggable]="false"
>
    <section>
        <div class="w-100 text-center">
            <h5><b>ICD-10</b></h5>            
        </div>        
        <div class="w-100 mt-1 mb-1">
            <span class="p-input-icon-left w-100 mb-2 mt-2">
                <i class="pi pi-search"></i>
                <input type="text" pInputText placeholder="Search" class="form-control" (keyup)="searchCode($event.target.value)" [(ngModel)]="searchText">     
            </span>
        </div>
        <div class="scrollable w-100 mb-2" style="height: 400px;">
            <table class="w-100 pl-3 pr-3">
                <tbody *ngIf="!processing">
                    <tr *ngFor="let code of codes?.data | paginate : {
                        id: 'paginator',
                        itemsPerPage: codes?.per_page,
                        currentPage: codes?.current_page,
                        totalItems: codes?.total
                    }; let i = index" (click)="selectICD(code)">
                        <td class="pb-1 align-top" style="width: 14%;"><b>{{ code?.icd_10_code}}</b></td>
                        <td class="align-top" style="width: 86%;">{{ code?.icd_10_description }}</td>
                    </tr>
                    <tr *ngIf="codes?.data?.length < 1">
                        <td colspan="2">No existing code/s</td>
                    </tr>
                </tbody>
            </table>
            <div class="document-header align-items-center d-flex justify-content-end mt-3 mb-3" *ngIf="!processing">
                <div class="d-flex align-items-center">
                    <pagination-controls
                        (pageChange)="list($event)"
                        id="paginator"
                    ></pagination-controls>      
                    <button  class="btn btn-dark ml-4" type="button" role="button" (click)="onClose()" [disabled]="processing">
                        Close
                    </button>          
                </div>   
            </div>
        </div>
    </section>
</p-dialog>
