import * as moment from 'moment';

export function getCurrentDate() {
  return moment(new Date()).format('YYYY-MM-DD');
}

export function formatDate(date, format = 'YYYY-MM-DD') {
  return moment(date).format(format);
}

export function generateExpiration() {

  let currentDate = moment();
  let currentMonth = currentDate.month() + 1;
  let currentYear = currentDate.year();

  let monthYearList = [];
  for (let year = currentYear; year < currentYear + 10; year++) {
    for (let month = 1; month <= 12; month++) {
      if (year === currentYear && month < currentMonth) {
        continue;
      }
      let monthYearString = moment({ year, month: month - 1 }).format('MM/YYYY');
      monthYearList.push(monthYearString);
    }
  }

  return monthYearList;
}
