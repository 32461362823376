
<form [formGroup]="form" *ngIf="form">
    <fieldset [disabled]="processing">
        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <span class="bold">FAMILY HISTORY</span>
                    <a class="cursor-pointer" (click)="editMode = true" *ngIf="!editMode && !processing">Edit Details</a>
                </div>
            </div>
            <div class="card-body">
                <table class="table table-bordered" *ngIf="!editMode">
                    <tbody style="border: none">
                        <tr style="border: none">
                            <td class="no-border bold">Relationship</td>
                            <td class="no-border bold">Condition/Illness</td>
                            <td class="no-border bold">Remarks</td>
                        </tr>
                    </tbody>
                    <tbody skeleton-loader [rows]="3" [columns]="3" *ngIf="processing"></tbody>
                    <tbody *ngIf="!processing">
                        <tr *ngFor="let item of items?.data">
                            <td>{{ getRelationship(item?.relationship_uid) }}</td>
                            <td>{{ item?.condition }}</td>
                            <td>{{ item?.remarks }}</td>
                        </tr>
                        <tr *ngIf="items?.data?.length < 1">
                            <td colspan="3">No Family History</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-bordered" *ngIf="editMode">
                    <tbody class="no-border">
                        <tr class="no-border">
                            <td class="no-border bold">Relationship</td>
                            <td class="no-border bold">Condition/Illness</td>
                            <td class="no-border bold">Remarks</td>
                            <td></td>
                        </tr>
                    </tbody>
                    <tbody skeleton-loader [rows]="3" [columns]="4" *ngIf="processing"></tbody>
                    <tbody *ngIf="!processing">
                        <tr *ngFor="let control of familyHistories?.controls; let i = index" [formGroup]="control">
                            <td>
                                <select class="form-control" formControlName="relationship_uid">
                                    <option value="null" hidden>Select</option>
                                    <option [value]="master?.uid" *ngFor="let master of relationships?.details">{{ master?.text }}</option>
                                </select>
                            </td>
                            <td><input type="text" class="form-control" formControlName="condition"/></td>
                            <td><input type="text" class="form-control" formControlName="remarks"/></td>
                            <td>
                                <button type="button" class="btn btn-default" (click)="add()" *ngIf="i === familyHistories?.controls?.length - 1"><i class="fas fa-plus text-primary"></i></button>
                                <button type="button" class="btn btn-default" (click)="remove(i)" *ngIf="i < familyHistories?.controls?.length - 1"><i class="fas fa-minus text-danger"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-right mt-3" *ngIf="editMode">
                    <button type="button" class="btn btn-primary mr-2" (click)="save()">Save</button>
                    <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </div>
    </fieldset>
</form>
