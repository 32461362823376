export const suffix = [
  {
    value: 'Sr.',
    display: 'Sr.',
    shortDisplay: 'Sr.',
  },
  {
    value: 'Jr.',
    display: 'Jr.',
    shortDisplay: 'Jr.',
  },
  {
    value: 'II',
    display: 'II',
    shortDisplay: 'II',
  },
  {
    value: 'III',
    display: 'III',
    shortDisplay: 'III',
  },
  {
    value: 'IV',
    display: 'IV',
    shortDisplay: 'IV',
  },
  {
    value: 'V',
    display: 'V',
    shortDisplay: 'V',
  },
  {
    value: 'VI',
    display: 'VI',
    shortDisplay: 'VI',
  },
  {
    value: 'VII',
    display: 'VII',
    shortDisplay: 'VII',
  },
  {
    value: 'VIII',
    display: 'VIII',
    shortDisplay: 'VIII',
  },
  {
    value: 'IX',
    display: 'IX',
    shortDisplay: 'IX',
  },
  {
    value: 'X',
    display: 'X',
    shortDisplay: 'X',
  },
]
