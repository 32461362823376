import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CURRENT_USER } from '../items/storage-names';
import { getStorage } from 'src/app/utils/helper/storage';
import { AppService } from '../services/app.service';

@Injectable({
  providedIn: 'root'
})
export class NewUserGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private _appService: AppService,) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let currentUser = this._appService.getCurrentUser();

    if (currentUser == null) {
      return false;
    } 
    // else if (currentUser.active_subscription == null) {
    //   this.router.navigateByUrl('/payment');
    //   return false;
    // }
     else if (+currentUser?.is_new_user === 1) {
      this.router.navigate([currentUser?.onboarding_route]);
      return false;
    }
    return true;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

}
