<div class="p-3 terms-wrapper">
    <h3 class="text-primary text-center bold mt-5">Privacy Policy and Terms & Conditions</h3>
    <!-- <p class="text-center text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p> -->
    <!-- <p class="mt-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac tortor aliquet, commodo justo tempus, posuere lacus. </p> -->
    <p class="mt-5">Terms and conditions use</p>
    <div class="terms-container mt-3">
        <p>These terms and conditions ("Terms of Use") constitute a legally binding agreement made between you ("you", "your") and Metro Pacific Health Tech Corporation Doing business under mWell PH (the "Company", "we", "us", or "our"), concerning your access to and use of our mobile application (the "Application", "mWell PH") as well as services we may provide through this Application and the telehealth and digital health platform provided by us and by our third party business partners, among others, CareSpan Asia, Inc., ePLDT, Inc., PayMaya Philippines Inc., TELUS Global Solutions Ltd., (collectively, the "Service"). You agree that by using the Service, you have read, understood, and agree to be bound by all of these Terms of Use. If you do not agree with (or cannot comply with) the Terms of Use, then you may not access or use the Application.</p>

        <ul style="list-style-type: decimal;">
            <li class="pl-2">
                <b>Acceptance of Terms of Use</b>
                <p class="mt-3">
                    These Terms of Use set out the legally binding terms of your use of and subscription to the Service. By using the Service, you acknowledge that you have read and understood, and you accept and agree to be bound by the terms, conditions, and notices contained or referenced in these Terms of Use.
                </p>
            </li>
            <li class="pl-2">
                <b>Availment of Service / Use of the Application</b>
                <p class="mt-3">
                    You will avail of the Service and use the Application only for legitimate and lawful purposes, and in accordance with these Terms of Use, including the terms of the Privacy Policy (as defined in Section 7), and you will ensure that any information you provide to us in availing the Service or through using the Application is true, accurate, current, and complete.
                </p>
            </li>
            <li class="pl-2">
                <b>Guiding Principles</b>
                <p class="mt-3">The following principles govern these Terms of Use:</p>
                <ul style="list-style-type: lower-alpha;">
                    <li class="mt-2 pl-2">Telemedicine services shall follow the standards of practice of medicine as defined under Republic Act No. 2382 (the "Medical Act"), its Implementing Rules and Regulations, and other applicable policies and guidelines, taking into account the absence of physical contact;</li>
                    <li class="mt-2 pl-2">The patient-physician relationship shall be based on full knowledge of the patient's medical history and a physical examination given the circumstances of a lack of physical contact. Telemedicine shall be employed when a licensed physician is physically inaccessible in the management of chronic health conditions, or follow-up check-ups after initial treatment;</li>
                    <li class="mt-2 pl-2">The patient-physician relationship shall be founded on mutual trust and respect in which they both identify themselves reliably during a telemedicine consultation. In case the patient is referred to a health facility, the physician who initially sees the patient shall be responsible for the coordination of care;</li>
                    <li class="mt-2 pl-2">Emergency and serious conditions, where face-to-face assessment and physical contact are most essential, should not be managed via telemedicine; and</li>
                    <li class="mt-2 pl-2">The use/implementation of telemedicine shall respect the universal principles of ethics, legal standards, and guiding principles on primacy of human rights and protection of health privacy as defined by Philippine laws, international instruments, rules, and other applicable policies.</li>
                </ul>
            </li>
            <li class="mt-2 pl-2">
                <b>Representations and Warranties</b>
                <p class="mt-3">You represent and warrant that:</p>
                <ul style="list-style-type: lower-alpha;">
                    <li class="mt-2 pl-2">All the information you provided to us are true and correct in all material respects;</li>
                    <li class="mt-2 pl-2">You are duly licensed to practice medicine by the Professional Regulation Commission;</li>
                    <li class="mt-2 pl-2">You are a member in good standing of the Philippine Medical Association;</li>
                    <li class="mt-2 pl-2">You have the specialized skills, expertise and experience to provide medical advice to the users on this background;</li>
                    <li class="mt-2 pl-2">You have no pending or decided cases and/or complaint for medical negligence and/or medical offenses; and</li>
                    <li class="mt-2 pl-2">You comply with all applicable laws and regulations while using the Application or while providing services through the Application.</li>
                </ul>
            </li>
            <li class="mt-2 pl-2">
                <b>Obligations of Doctors</b>
                <p class="mt-3">You undertake to:</p>
                <ul style="list-style-type: lower-alpha;">
                    <li class="mt-2 pl-2">conduct yourself in a professional manner and shall observe the International Code of Medical Ethics and the Code of Ethics as approved by the Philippine Medical Association;</li>
                    <li class="mt-2 pl-2">comply with all laws and regulations relating to the practice of medicine;</li>
                    <li class="mt-2 pl-2">comply with applicable industry standards imposed by the Department of Health, including standards and regulations for Coronavirus (COVID-19);</li>
                    <li class="mt-2 pl-2">comply with other relevant laws, including, but not limited to, the Data Privacy Act of 2012 and its implementing rules and regulations ("DPA");</li>
                    <li class="mt-2 pl-2">comply with the standards for personal data protection as set by the National Privacy Commission ("NPC") and the Department of Information and Communications Technology in the collection and processing of health information and management of privileged communication for telemedicine services;</li>
                    <li class="mt-2 pl-2">uphold and protect at all times the data privacy rights of every patient or individual who will consult through telemedicine;</li>
                    <li class="mt-2 pl-2">comply with all guidelines that MPHTC shall prescribe in connection with the use of the Application;</li>
                    <li class="mt-2 pl-2">make yourself available at the days and times scheduled for teleconsultations;</li>
                    <li class="mt-2 pl-2">maintain skills, qualifications, eligibility and other information and requirements, including status in good standing as a licensed medical professional;</li>
                    <li class="mt-2 pl-2">provide telemedicine consultations personally, and not delegate the same to any other person;</li>
                    <li class="mt-2 pl-2">verify the identity of the patient at the start of a telemedicine consultation;</li>
                    <li class="mt-2 pl-2">protect and respect the right to privacy of the patients and Users at all times, and secure the consent of the patient before any video or audio recording of the telemedicine consultation is allowed;</li>
                    <li class="mt-2 pl-2">exercise professional judgment and discretion in gathering the type and extent of patient information required to be able to exercise proper clinical judgment, at all times in compliance with the DPA;</li>
                    <li class="mt-2 pl-2">provide the same level of care as in a face-to-face consultation to Users of the Applications, subject to the intrinsic limitations of telemedicine consultations;</li>
                    <li class="mt-2 pl-2">request the User for a face-to-face consultation if in your professional judgment the same is appropriate in a given situation and the specific conditions of the User;</li>
                    <li class="mt-2 pl-2">recognize and deem equivalent the electronic clinical abstract, consultation summary, prescription, and referral form issued by the physician for all intents and purposes;</li>
                    <li class="mt-2 pl-2">keep records of all electronic clinical abstracts/consultation summaries, prescriptions and/or referral forms issued in coordination with us;</li>
                    <li class="mt-2 pl-2">issue electronic prescriptions in accordance with FDA Circular No. 2020-007 and any subsequent FDA guidelines; and</li>
                    <li class="mt-2 pl-2">ensure, at all times, that patient confidentiality, privacy, and data integrity are not compromised.</li>
                </ul>
            </li>
            <li class="mt-2 pl-2">
                <b>Professional Fee</b>
                <p class="mt-3">You shall receive your professional fee from the Users net of the Company'’'s commission. The fees, payment options and payment schedules are set and determined exclusively by us and may be changed in our sole discretion. You are solely responsible for paying any and all applicable tax in relation to your use of the Application.</p>
            </li>
            <li class="pl-2">
                <b>Privacy Policy</b>
                <p class="mt-3">The Company'’'s Privacy Policy (the "Privacy Policy"), as may be amended from time to time and may be accessible through the Application, is hereby incorporated by reference to these Terms of Use. The Privacy Policy explains how we use the information you disclose to the Company in availing of the Service and in using the Application. The Company takes utmost care to never disclose your data and information, except to the extent necessary to inform the users of the identity of their telemedicine consultation provider, to render telemedicine services, upon your request, if the same is necessary to comply with legal, statutory, or investigative law enforcement requirements, or as otherwise provided in the Privacy Policy.</p>
            </li>
            <li class="pl-2">
                <b>Changes</b>
                <p class="mt-3">We reserve the right, in our sole discretion, to amend these Terms of Use, or to make changes to the functionality, features, or content of the Application or Service at any time and for any reason. You will be notified of any material changes via e-mail, or via the posting of an updated version thereof on our Application. All changes shall take effect seven (7) days after the date of our e-mail or the date on which we posted the modified terms on our Application, as the case may be. If you do not agree with any aspect of the updated Terms of Use, Application and/or Service, you must immediately cease availing the Service or using the Application.</p>
            </li>
            <li class="pl-2">
                <b>Intellectual Property</b>
                <p class="mt-3">The Service, the Application and all proprietary and intellectual property rights therein are and shall be owned by the Company. All rights reserved.</p>
            </li>
            <li class="pl-2">
                <b>Term and Termination</b>
                <p class="mt-3">These Terms of Use take full force and effect on the date on which you commence availing of the Service or using the Application, whichever is earlier, and shall continue unless your availment of Service or use of the Application is terminated in accordance with the Terms of Use. We reserve the right to terminate or suspend your account or access to the Service and Application at any time and for any reason.</p>
            </li>
            <li class="pl-2">
                <b>Force Majeure</b>
                <p class="mt-3">We shall not be responsible for damages, delays, or non-performance of the Service resulting from acts or occurrences beyond our reasonable control, including, but not limited to, fire, lightning, explosion, power surge or failure, internet connection problems or downtime, flood, typhoon, acts of God, epidemic, pandemic, government-imposed quarantines, war, revolution, civil commotion or acts of civil or military authorities or public enemies; any law, order, regulation, ordinance or requirement of any government or legal body or any representative of any such government or legal body; or labor unrest, including, without limitation, strikes, slowdowns, picketing, or boycotts; inability to secure raw materials, transportation facilities, fuel or energy shortages, or acts or omissions of other common carriers.</p>
            </li>
            <li class="pl-2">
                <b>Third-Party Content and Sites</b>
                <p class="mt-3">The Services may include third-party content and links to other websites that are not owned by the Company, including the telehealth and digital health platform provided by, among others, CareSpan Asia Inc., ePLDT, Inc., PayMaya Philippines Inc., TELUS Global Solutions Ltd., ("Third-Party Content and Sites"). By accessing the Third-Party Content and Sites, you agree to the terms and conditions of these Third-Party Content and Sites and agree to hold harmless and release the Company, its shareholders, subsidiaries, affiliates, officers, directors, employees and representatives from any claims, demands and damages of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with your use of Third-Party Content and/or Sites.</p>
            </li>
            <li class="pl-2">
                <b>Disclaimer of Warranties</b>
                <p class="mt-3">You acknowledge and agree that you avail the Service and use the Application at your own risk. The Service is provided on an "as is" and "as available" basis, without warranties of any kind, and to the maximum extent permitted by law, the Company, its officers, directors, employees, agents, subsidiaries, and affiliates, disclaim all warranties express or implied, including, but not limited to, the warranties of merchantability, fitness for a particular purpose, title, non-infringement, and those arising from course of dealing or usage of trade. We do not warrant that you will be able to avail of the Service or use or access the Application at the times or locations of your choosing; that the Service and use of the Application will be uninterrupted or error-free, reliable, accurate, timely, useful, adequate, complete or suitable; that defects in the performance of Service or in the Application will be corrected; or that the Application is free of viruses or other harmful components.</p>
            </li>
            <li class="pl-2">
                <b>Indemnity</b>
                <p class="mt-3">You agree to defend, indemnify, and hold the Company, its parent, subsidiaries, affiliates, officers, employees, agents, partners, and licensors harmless from any losses, costs, liabilities, claims, causes of action, and expenses (including reasonable attorneys'’' fees) relating to or arising out of, directly or indirectly, your acts in using the Application, including: (a) any wrong medication, treatment or medical negligence; (b) any misrepresentation, misconduct or inappropriate behavior; (c) cancellation or rescheduling of booked appointment or any variance in the fees charged; and (d) any direct or indirect medical eventualities that might occur subsequent to the provision of your service to the Users through the Application. The Company reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with the Company in asserting any available defenses. You agree that the provisions in this section will survive the termination of your account, the Terms of Use, or your access to the Application.</p>
            </li>
            <li class="pl-2">
                <b>Limitation on Liability</b>
                <p class="mt-3">Other than in case of our fraud or gross negligence, we shall not be liable to you for any indirect, incidental, consequential, special, punitive, remote or other similar damages, including, but not limited to, loss of revenues, lost profits, lost data or business interruption or other intangible losses (however such losses are qualified), arising out of or relating in any way to these Terms of Use, the Services or the use of the Application, whether based on contract, tort or any other legal theory, and whether or not we have been advised of the possibility of such damages. In circumstances where the foregoing limitation is finally determined to be inapplicable, you acknowledge and agree that our aggregate liability for any damages, losses, fees, charges, expenses or liabilities shall not exceed Fifty Thousand Pesos (PhP 50,000.00) or the total amount of your unpaid fees in our possession, whichever is higher. In the event of any problem with the Service and/or the Application, you agree that your sole remedy is to cease the availment of the Service and/or use of the Applications.</p>
            </li>
            <li class="pl-2">
                <b>Assignment</b>
                <p class="mt-3">You acknowledge and agree that the Company may assign its rights and obligations under these Terms of Use without notice to you. You shall not assign your rights and obligations in connection with your account and under these Terms of Use without the prior written consent of the Company (such consent may be withheld or conditioned, at our sole discretion). Any assignment without our prior written consent shall be null and void and of no effect.</p>
            </li>
            <li class="pl-2">
                <b>Data Sharing Agreement</b>
                <p class="mt-3">You hereby agree that the Users'’' or patients'’' ("Data Subjects") data that you will obtain from us in the course of using the Service may include Personal Information and/or Sensitive Personal Information as defined under the DPA, including their name, age, gender, medical information and medical history, health complaints, vital signs, results of laboratory examinations and other information necessary for diagnosis ("Data"). You agree that the Data collected, processed and shared pursuant hereto shall be processed and shared exclusively for the purpose of your performance of services contemplated herein, unless otherwise expressly allowed under the DPA.</p>
                <p>You acknowledge that the Data will be shared between you and us through the Application which are encrypted and has security features that will prevent unauthorized access. You will not transmit any document containing Data through facsimile or other unencrypted means.</p>
                <p>You agree that you will (a) not disclose, trade, sell or transfer any Data to any person without our prior written instruction; (b) not use or process the Data except in connection with the purposes provided herein; (c) as applicable, allow Data Subjects to access and correct the Data in your custody and to exercise their rights under the DPA, (d) notify us if you receive from us any Data that is not covered by this clause, and (e) comply with our Cybersecurity Policy.</p>
                <p>Each of you and us represents and warrants that (a) the collection and processing of Data contemplated herein adheres to the principles of transparency, legitimate purpose and proportionality, (b) we will fully comply with the provisions of these Terms of Use and the DPA, (c) these Terms of Use shall be made available for review of the NPC in cases required under the DPA; and (d) the rights of the Data Subjects shall be upheld without compromising data integrity.</p>
                <p>We represent and warrant that (a) prior to collecting the Data, or prior to the sharing of the Data, we will secure the consent of the Data Subject with respect to the collection and further processing of the Data and the sharing, storage, transfer and transmission of the same in accordance with these Terms of Use. We will secure the Data Subject'’'s consent through written, electronic or recorded means, and (b) we will provide the following information to the Data Subjects prior to the collection or sharing of Data, (i) identity of the third parties who will be given access to the Data, (ii) purpose of the data sharing, (iii) categories of Data concerned, (iv) intended recipients of categories of recipients of the Data, (v) existence of the rights of the Data Subjects, including the right to access and to correct the Data, and the right to object to Data sharing or further processing, (vi) if applicable, the automated processing of the Data for profiling, and (vii) other information that would sufficiently notify the Data Subject of the nature and extent of Data sharing and manner of processing.</p>
                <p>Each of you and us will implement reasonable and appropriate physical, technical, and organizational measures for the protection of Data, with the aim to maintain the availability integrity and confidentiality of Data and protect them against natural dangers such as accidental loss or destruction, and human dangers such as unlawful access, fraudulent misuse, unlawful destruction, alteration and contamination.</p>
                <p>In the event of a Personal Data Breach involving the Data while the same is being processed by you or is in your custody or control, you agree to immediately notify us in writing, and in no case later than six (6) hours upon knowledge of the same, or when you have reasonable belief that a Personal Data Breach has occurred. Your notification shall describe the nature of the breach, the type of Data possibly involved, the list of Data Subjects involved, the possible risks, harm or damage to the Data Subjects and the measures taken by you to address the breach. You agree to include as well your assessment of whether there is a need to notify the NPC and the Data Subjects of the breach for our consideration.</p>
                <p>This specific clause shall be co-terminus with the Terms of Use which constitutes a legally binding agreement between us, provided that this Clause 17 and any extension thereof shall in no case be for more than five (5) years.</p>
                <p>Upon the termination of your availment of the Service, or upon our request, you shall promptly destroy, dispose or surrender to us, as applicable, the Data and other information and documents containing the Data in your possession and if requested by us, you agree to certify that the Data and/or copies thereof in your possession have been destroyed, disposed of, surrendered or returned in compliance with this paragraph.</p>
                <p>As applicable, each of you and us shall ensure that the following rights of the Data Subjects are upheld:</p>
                
                <ul style="list-style-type: lower-alpha;">
                    <li>
                        Availability of these Terms of Use
                        <p class="mt-2">A copy of these Terms of Use may be accessed by a Data Subject through email upon written request, provided that the parties have a right to prevent the disclosure of any detail or information that could endanger the security measures set out herein or expose to harm the integrity, availability, or confidentiality of Data under its control or custody.</p>
                    </li>
                    <li>
                        Access to Information
                        <p class="mt-2">Upon the request of a Data Subject, the concerned party may release to the requesting Data Subject the following information with respect to his Data:</p>
                        <ul style="list-style-type: lower-roman;">
                            <li class="mt-2 pl-2">Contents of his/her Data that were processed;</li>
                            <li class="mt-2 pl-2">Sources from which his/her Data were obtained;</li>
                            <li class="mt-2 pl-2">Names and addresses of the recipients of his/her Data;</li>
                            <li class="mt-2 pl-2">Manner by which his/her Data was processed;</li>
                            <li class="mt-2 pl-2">Reasons for the disclosure of his/her Data to recipients, if any;</li>
                            <li class="mt-2 pl-2">Date when his/her Data was last accessed or modified; and</li>
                            <li class="mt-2 pl-2">The identity and address of the owner of the Data.</li>
                        </ul>
                    </li>
                    <li class="mt-2">
                        Correction of Data
                        <p class="mt-2">The concerned party may provide to the Data Subject the means and methods whereby the Data Subject may dispute the inaccuracy or error in the Data and correct the Data immediately and accordingly, unless the request is vexatious or otherwise unreasonable. The concerned Party shall inform the other Party of the correction or rectification.</p>
                    </li>
                    <li>
                        Deletion of Data
                        <p class="mt-2">The concerned Party may provide to the Data Subject the means and methods whereby the Data Subject may order the blocking, removal, or destruction of his/her Data.</p>
                    </li>
                    <li>
                        Remedies
                        <p class="mt-2">The concerned party may provide to the Data Subject the means and methods whereby the Data Subject may report any security breach or violation of his rights with respect to the processing of his/her Data, as well as the procedures whereby such reports are timely and adequately addressed.</p>
                    </li>
                </ul>
            </li>
            <li class="pl-2">
                <b>Dispute Resolution and Governing Law</b>
                <p class="mt-3">These Terms of Use shall be governed by and construed in accordance with the laws of the Philippines without reference to conflict of laws principles, and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of the proper courts of Makati City, Philippines.</p>
                <p>You agree that we may elect to resolve the dispute in a cost-effective manner through arbitration in Makati City, Philippines under the rules of the Philippine Dispute Resolution Center, then in force, using the English language. Any arbitration award shall be final and binding upon the parties and may be enforced by judgment of a competent court having jurisdiction.</p>
            </li>
            <li class="pl-2">
                <b>Entire Agreement and Severability</b>
                <p class="mt-3">These Terms of Use shall constitute the entire agreement between you and the Company concerning the Service. If any provision of these Terms of Use is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms of Use, which shall remain in full force and effect.</p>
            </li>
        </ul>
    </div>
    <div class="form-group mt-4 mb-4">
        <input type="checkbox" class="cursor-pointer" [(ngModel)]="userAgree">
        I agree to Privacy Policy and terms & Conditions as it is stated above.
    </div>
    <button type="button" (click)="agreeTerms()" class="btn btn-primary btn-block btn-lg" [disabled]="!userAgree">Agree</button>
</div>