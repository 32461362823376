import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { isAcceptableImagePdf } from 'src/app/utils/helper/utilities';
import { FormService } from 'src/app/utils/services/forms/forms.service';

@Component({
  selector: 'app-document-uploader',
  templateUrl: './document-uploader.component.html',
  styleUrls: ['./document-uploader.component.scss']
})
export class DocumentUploaderComponent implements OnInit {

  @Output()
  uploaded: EventEmitter<any> = new EventEmitter<any>();
  
  @Input()
  patientID: any;

  document: any;
  modal = false;
  processing = false;
  tags: any = [];
  form: FormGroup;
  file: any;
  preview: any;

  constructor(
    private service: FormService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.document?.id],
      patient_id: [this.patientID],
      name: [this.document?.name, Validators.required],
      renamed: [this.document?.renamed],
      version: [this.document?.version],
      type: [this.document?.type],
      source: [this.document?.source || 'uploaded'],
      description: [this.document?.description],
      tags: [],
    });
  }

  previewUploadFile(event) {
    var id = event.target.id;
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if(!isAcceptableImagePdf(file?.type)) {
        this.toastr.error('File should be either an image or pdf','', { timeOut: 5000 });
        return;
      }
      
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.form.patchValue({
          type: file?.type
        })
        this.file = file;
        this.preview = reader.result as string;
      };
    }
  }

  updateTags() {
    this.form.patchValue({
      tags: this.tags?.map((item) => item)?.join(',')
    });
  }

  onOpen(document = null) {
    this.document = document;
    this.preview = this.document?.url;
    this.tags = document?.tags?.split(',');
    this.modal = true;
    this.createForm();
    setTimeout(() => {
      this.updateTags();
    }, 1);
  }

  onClose() {
    this.modal = false;
    this.file = null;
    this.preview = null;
    this.tags = [];
  }

  getControl(name) {
    return this.form.get(name);
  }

  async upload() {
    this.processing = true;

    if(this.file) {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("disk", "public");
      formData.append("folder", "uploaded");
      formData.append("mimes", 'mimes:png,jpg,jpeg,png,bmp,gif,webp,image,pdf');
  
      const response: any = await this.service.upload(formData).toPromise();
  
      this.form.patchValue({  
        renamed: response?.renamed
      });
    }

    await this.service.saveFile(this.form.value)
    .subscribe((response: any) => {
      this.toastr.success("Successfully uploaded the document");
      this.uploaded.emit(response);
      this.onClose();
      this.processing = false;
    }, (err) => {
      this.processing = false;
    });
    
  }
}
