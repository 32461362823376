import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { getStorage } from 'src/app/utils/helper/storage';
import { CURRENT_USER } from 'src/app/utils/items/storage-names';
import { BillingService } from 'src/app/utils/services/doctor/billing.service';

@Component({
  selector: 'app-create-billable-item',
  templateUrl: './create-billable-item.component.html',
  styleUrls: ['./create-billable-item.component.scss']
})
export class CreateBillableItemComponent implements OnInit {

  @Output()
  create: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  clinicID: any;

  user: any;
  modal = false;
  processing = false;
  categories: any;
  form: any;

  constructor(
    private service: BillingService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.user = getStorage(CURRENT_USER, true);
    this.getCategories();
  }

  getCategories(){
    this.service.getBillableCategories()
    .subscribe((response: any) => {
      this.categories = response;
    });

  }

  createForm() {
    this.form = this.fb.group({
      cat_id: [null, [Validators.required]],
      cat_name: [null],
      cat_description: [null],
      item_name: [null, [Validators.required]],
      item_description: [null],
      base_price: [null, [Validators.required]],
      pf_tracking: [null || false],
      clinic_id: [this.clinicID]
    });
  }

  createItem() {
    this.processing = true;
    this.service.saveBillableItem(this.form.value)
    .subscribe((response: any) => {
      this.create.emit(response);
      this.onClose();
    }, (err) => {
      this.processing = false;
    });
  }

  onSelectCategory() {
    const category = this.categories?.data?.find((item) => +item?.id === +this.form.value.cat_id);
    this.form.patchValue({
      cat_name: category?.cat_name,
      cat_description: category?.cat_description
    });
  }

  onOpen() {
    this.modal = true;
    this.processing = false;
    this.createForm();
  }

  onClose() {
    this.modal = false;
  }

  getControl(name) {
    return this.form.get(name);
  }
}
