import { NgSignaturePadOptions } from '@almothafar/angular-signature-pad';
import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import SignaturePad, { Options, PointGroup } from 'signature_pad';
import { FormService } from 'src/app/utils/services/forms/forms.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-scribble',
  templateUrl: './scribble.component.html',
  styleUrls: ['./scribble.component.scss']
})
export class ScribbleComponent implements OnInit, OnChanges {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.clear();
    this.scaleImage();
  }

  @Input()
  form: FormGroup;

  @Input()
  name: string;

  @Input()
  control: FormControl;

  @Input()
  saving = false; 

  innerWidth: any;
  heightPercent: any;

  // reference = HUMAN;
  default = `${environment.forms_url}/images/blank_scribble.png`;
  reference = `${environment.forms_url}/images/blank_scribble.png`;

  penColor = '#000000';
  tool = 'brush';
  history = [];
  scribblePad: SignaturePad;
  referrencePad: SignaturePad;

  signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 2,
    maxWidth: 2,
    canvasWidth: 500,
    canvasHeight: 300,
    // backgroundColor: 'rgba(255, 255, 255, 0)'
  };

  constructor(
    private service: FormService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.saving) {
      if(this.saving) {
        this.scribblePad?.off();
      } else {
        this.scribblePad?.on();
      }
    }
  }

  ngAfterViewInit() {
    this.innerWidth = window.innerWidth * .8;
    setTimeout(() => {      
      this.init();
    }, 500);
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth * .8;
    this.reference = this.form?.value?.source || this.default;
  }

  init() {
    const source = document.getElementById('annotator-container') as HTMLElement;
    const referrenceImage = document.getElementById('referrence-image') as HTMLImageElement;
    const penSize = document.getElementById('pen-size') as HTMLInputElement;
    
    const options: NgSignaturePadOptions = {
      minWidth: +penSize.value,
      canvasWidth: this.innerWidth = source.clientWidth,
      canvasHeight: source.clientWidth * (this.heightPercent = referrenceImage.height / referrenceImage.width),
    };

    this.scribblePad = new SignaturePad(document.getElementById('signature-pad') as HTMLCanvasElement, options);
    this.referrencePad = new SignaturePad(document.getElementById('referrence-pad') as HTMLCanvasElement, options);
    this.referrencePad.off();
    this.referrencePad.clear();

    this.referrencePad.fromDataURL(referrenceImage?.src, {
      width: this.innerWidth,
      height: this.innerWidth * this.heightPercent,
      ratio: Math.max(window.devicePixelRatio || 1, 1)
    });
    
    setTimeout(() => {
      this.scribblePad.fromData(this.form.value?.points || []);
      this.scribblePad.penColor = this.penColor;
      this.setSize(+penSize.value);
    }, 1);
  }
  
  scaleImage() {
    const referrenceImage = document.getElementById('referrence-image') as HTMLImageElement;
    const source = document.getElementById('annotator-container') as HTMLElement;
    const scribbleCanvas = document.getElementById('signature-pad') as HTMLCanvasElement;
    const referenceCanvas = document.getElementById('referrence-pad') as HTMLCanvasElement;
    const ratio =  Math.max(window.devicePixelRatio || 1, 1);

    this.innerWidth = source.clientWidth;
    this.heightPercent = referrenceImage.height / referrenceImage.width;
    
    this.referrencePad.fromDataURL(referrenceImage?.src, {
      width: this.innerWidth,
      height: this.innerWidth * this.heightPercent,
      ratio: Math.max(window.devicePixelRatio || 1, 1)
    });

    scribbleCanvas.width = referenceCanvas.width = this.innerWidth * ratio;
    scribbleCanvas.height = referenceCanvas.height = (this.innerWidth * this.heightPercent) * ratio;

    scribbleCanvas.getContext("2d").scale(ratio, ratio);
    referenceCanvas.getContext("2d").scale(ratio, ratio);
  }

  async save() {
    this.saving = true;
    return new Promise((resolve, reject) => {
      const promises = [];

      const formData = new FormData();
      formData.append("file", this.scribblePad.toDataURL("image/png"));
      formData.append("disk", "public");
      formData.append("folder", "annotations");
      formData.append("mimes", 'mimes:png,jpg,jpeg,png,bmp,gif,webp,image,pdf');
      promises.push(this.service.upload64(formData).toPromise());

      if(this.reference?.includes('base64')) {
        const sourceData = new FormData();
        sourceData.append("file", this.reference);
        sourceData.append("disk", "public");
        sourceData.append("folder", "annotations");
        sourceData.append("mimes", 'mimes:png,jpg,jpeg,png,bmp,gif,webp,image,pdf');
        promises.push(this.service.upload64(sourceData).toPromise());
      }

      Promise.all(promises)
      .then((responses: any) => {
        
        this.form.get('points').setValue(this.scribblePad.toData());
        this.form.get('points').updateValueAndValidity();
        this.form.get('source').setValue(responses?.[1]?.self || this.reference);
        this.form.get('source').updateValueAndValidity();
        this.form.get(this.name).setValue(responses?.[0]?.self);
        this.form.get(this.name).updateValueAndValidity();
  
        this.saving = false;
        resolve(true);
      });
    });
  }

  uploadImage(event) {
    if(event?.target?.files?.length < 1) return;

    const [file] = event.target.files;
    const reader = new FileReader();
    const image = new Image();

    reader.readAsDataURL(file);
  
    reader.onload = async () => {
      
      image.src = this.reference = reader.result as string;
      image.crossOrigin = '*';

      image.onload = () => {
        // this.signaturePad.clear();
        const source = document.getElementById('annotator-container') as HTMLElement;
        const canvas = document.getElementById('signature-pad') as HTMLCanvasElement;
        const referrenceImage = document.getElementById('referrence-image') as HTMLImageElement;
        const penSize = document.getElementById('pen-size') as HTMLInputElement;
        
        const options: NgSignaturePadOptions = {
          minWidth: +penSize.value,
          canvasWidth: this.innerWidth = source.clientWidth,
          canvasHeight: source.clientWidth * (this.heightPercent = referrenceImage.height / referrenceImage.width),
        };
    
        this.referrencePad = new SignaturePad(document.getElementById('referrence-pad') as HTMLCanvasElement, options);
        this.referrencePad.off();
        this.referrencePad.clear();

        this.referrencePad.fromDataURL(referrenceImage?.src, {
          width: this.innerWidth,
          height: this.innerWidth * this.heightPercent,
          ratio: Math.max(window.devicePixelRatio || 1, 1)
        });

        canvas.width = this.innerWidth;
        canvas.height = this.innerWidth * this.heightPercent;
    
        setTimeout(() => {
          this.clear();
        }, 1);
      }
    };
  }

  setBrush() {
    this.tool = 'brush';
    const canvas = document.getElementById('signature-pad') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');
    ctx.globalCompositeOperation = 'source-over';
  }

  setEraser() {
    this.tool = 'eraser';
    const canvas = document.getElementById('signature-pad') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');
    ctx.globalCompositeOperation = 'destination-out';
  }

  setSize(value) {
    this.scribblePad.maxWidth = value;
    this.scribblePad.minWidth = value;
    this.scribblePad.dotSize = value / 2;
  }

  setColor(color) {
    this.scribblePad.penColor = this.penColor = color?.value;
  }

  undo() {
    const data = this.scribblePad.toData();
    if (data?.length > 0) {
      const history = data.pop();
      this.history.push(history);
      this.scribblePad.fromData(data);
    }
  }

  redo() {
    if (this.history?.length > 0) {
      var data = this.scribblePad.toData();
      data.push(this.history.pop());
      this.scribblePad.fromData(data);
    }
  }

  trackMouse(event) {
    // const cursor = document.getElementById('cursor-radius') as HTMLElement;
    // cursor.style.left = event.offsetX + 'px';
    // cursor.style.top = event.offsetY + 'px';
  }

  clear() {
    this.scribblePad.clear();
    this.form.get('points').setValue(this.scribblePad.toData());
    this.form.get('points').updateValueAndValidity();
    this.history = [];
  }

  drawComplete(event: MouseEvent | Touch) {
    this.history = [];
  }

  drawStart(event: MouseEvent | Touch) {
    // TODO
  }

}
