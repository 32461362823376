<p-autoComplete
  showEmptyMessage="true"
  appendTo="body"
  [placeholder]="placeholder"
  [ngModelOptions]="{ standalone: true }"
  [(ngModel)]="selectedResult"
  styleClass="autocomplete-form"
  panelStyleClass="autocomplete-form-panel"
  [field]="field"
  [delay]="300"
  baseZIndex="200"
  inputStyleClass="form-control"
  [unique]="true"
  [multiple]="isMultiple"
  [suggestions]="results"
  [forceSelection]="forceSelection"
  (onClear)="onClear($event)"
  (onUnselect)="onUnselect($event)"
  (onSelect)="onSelect($event)"
  (completeMethod)="onSearch($event)"
></p-autoComplete>
