<div class="card bg-white mwell-card mb-2" *ngIf="patient">
    <div class="card-body">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="container info-container">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div><h5>{{ patient?.display }}</h5></div>
                            <div>{{ patient?.date_of_birth_string }}</div>
                            <div>{{ patient?.age_sex_complete }}</div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div><span class="font-weight-bold">Height (cm): </span> {{ patient?.latest_basic_anthropometrics?.height }}</div>
                            <div><span class="font-weight-bold">Weight (kg): </span> {{ patient?.latest_basic_anthropometrics?.weight }}</div>
                            <div class="date">as of {{ patient?.latest_basic_anthropometrics?.created_at_formatted }}</div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div><span class="font-weight-bold">BMI: </span> {{ patient?.latest_basic_anthropometrics?.body_mass_index }}</div>
                            <div><span class="font-weight-bold">WHO Classification: </span>{{ patient?.latest_basic_anthropometrics?.who_classification }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="container allergies-container">
                    <div><h5>Allergies</h5>{{ allergies || 'None' }}</div>
                </div>
            </div>
        </div>
        <!-- <div class="d-flex justify-content-between align-items-start">
            <div>
                <h5 class="text-color"><b>{{ patient?.display }}</b></h5>
                <span class="normal-weight mb-0">{{ patient?.patient_id }} | {{ patient?.age_sex_complete }}<span class="ml-3 mr-3"> | </span>{{ patient?.date_of_birth_string }}</span>
            </div>
            <h5 class="mr-auto text-color normal-weight ml-3">({{ patient?.patient_id }})</h5>
            <button class="btn btn-success btn-lg hidden" (click)="startConsultation()" *ngIf="inprogressAppointment && (videoCall?.currentCall?.state === 'Disconnected' || !videoCall?.currentCall?.state)" [disabled]="!videoCall?.isReady">
                <i class="fas fa-phone-alt mr-2"></i>Start Call
            </button>
        </div> -->
    </div>
    <!-- <div class="card-footer" style="background-color: transparent">
        <div class=" d-flex justify-content-between align-items-start">
            <h5 class="mr-auto text-color normal-weight">
                <a class="mr-2 cursor-pointer" (click)="allergyModal?.onOpen()">Allergies :</a>
                <span class="text-danger">
                    {{ allergies || 'None' }}
                </span>
            </h5>
            <div *ngIf="patient.latest_basic_anthropometrics != null" class="text-right basic-anthropometrics-container px-2 px-sm-0">
                <span class="mr-auto text-color normal-weight">Upcoming Appointment: {{ appointment?.appointment_datetime || 'None'}}</span>
                <div class="row text-left">
                    <div class="col-12 col-sm-6"><span class="font-weight-bold">Height (cm): </span> {{ patient.latest_basic_anthropometrics.height }}</div>
                    <div class="col-12 col-sm-6"><span class="font-weight-bold">BMI: </span> {{ patient.latest_basic_anthropometrics.body_mass_index }}</div>
                    <div class="col-12 col-sm-6"><span class="font-weight-bold">Weight (kg): </span> {{ patient.latest_basic_anthropometrics.weight }}</div>
                    <div class="col-12 col-sm-6"><span class="font-weight-bold">WHO Classification: </span>{{ patient.latest_basic_anthropometrics.who_classification }}</div>
                    <div class="col-12 date">as of {{ patient.latest_basic_anthropometrics.created_at_formatted }}</div>
                </div>
            </div>
        </div>
  
    </div>
    <div class="card-footer" style="background-color: transparent"  *ngIf="patient?.active_admission">
        <div class=" d-flex justify-content-between align-items-start">
            <p class="">
                <span class="mr-2 font-weight-bold">Room/Location :</span>
                <span>
                    {{ patient?.active_admission?.location_room || '-' }}
                </span>
            </p>
            <div class="text-right">
                <span class="mr-auto font-weight-bold">Attending Physician:  </span>
                <span>{{ patient?.active_admission?.attending_physician }}</span>
            </div>
        </div>  
        <div class=" d-flex justify-content-between align-items-start">
            <p class="">
                <span class="mr-2 font-weight-bold">Hospital :</span>
                <span>
                    {{ patient?.active_admission?.hospital_master?.facility_name || patient?.active_admission?.hospital_short_name || '-' }}
                </span>
            </p>
            <div class="text-right">
                <span class="mr-auto font-weight-bold">Admission Status: </span>
                <span>Admitted</span>
            </div>
        </div>  
    </div> -->
</div>
<div class="card bg-white" *ngIf="!patient">
    <div class="card-body d-flex justify-content-between align-items-start" *ngIf="!patient">
        <div>
            <p-skeleton borderRadius="5px" height="35px" width="300px"></p-skeleton>
            <div class="d-flex mt-2"><p-skeleton borderRadius="5px" height="35px" width="70px" class="mr-2"></p-skeleton> <p-skeleton height="35px" width="150px"></p-skeleton></div>
        </div>
        <div class="d-flex w-100 pl-3"><p-skeleton borderRadius="5px" height="35px" width="70px"></p-skeleton></div>
        <div class="d-flex justify-content-end w-100 pl-3"><p-skeleton borderRadius="5px" height="50px" borderRadius="20px" width="120px"></p-skeleton></div>
    </div>
    <div class="card-footer d-flex justify-content-between align-items-start" style="background-color: transparent">
        <h5 class="mr-auto text-color normal-weight">&nbsp;</h5>
        <div class="text-right">
            <p-skeleton borderRadius="5px" height="35px" width="300px"></p-skeleton>
        </div>
    </div>
    <div  *ngIf="patient?.active_admission" class="card-footer d-flex justify-content-between align-items-start" style="background-color: transparent">
        <h5 class="mr-auto text-color normal-weight">&nbsp;</h5>
        <div class="text-right">
            <p-skeleton borderRadius="5px" height="35px" width="300px"></p-skeleton>
        </div>
    </div>
</div>
<app-allergy-modal #allergyModal [patientID]="patient?.patient_id"></app-allergy-modal>