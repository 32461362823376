<img class="logo" src="assets/img/logo.png" width="150px">

<!-- /.login-logo -->
<div class="card light-shadow">
  <div class="card-body login-card-body text-center">
    <!-- <h5>Please login to get started</h5> -->
    <div id="account-management" class="carousel slide" data-ride="carousel" data-interval="false" >
      <ol class="carousel-indicators" style="display: none">
          <li
            data-target="#account-management"
            data-slide-to="0"
            class="active"
            #resetIndicator
          ></li>
          <li
            data-target="#account-management"
            data-slide-to="1"
            #confirmIndicator
          ></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="login-form">
            <div class="w-100">
              <h2 class="text-primary bold">Reset your password</h2>
              <form [formGroup]="loginForm">
                <fieldset [disabled]="processing">
                  <div class="input-group mb-4 mt-4" *ngIf="!processing">
                    <input
                      formControlName="email"
                      type="email"
                      class="form-control"
                      placeholder="Email"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3" *ngIf="processing">
                    <div class="col">
                      <i class="ml-2 fas fa-circle-notch spinning"></i>
                    </div>
                  </div>
                  <div class="row mt-3" *ngIf="!processing">
                    <div class="col">
                      <button
                        (click)="resetPassword()"
                        type="submit"
                        class="btn btn-primary btn-block btn-flat btn-lg"
                        [disabled]="loginForm?.invalid"
                      >
                        Reset password
                        <i *ngIf="processing" class="ml-2 fas fa-circle-notch spinning"></i>
                      </button>
                      <!-- <button class="btn btn-danger btn-block btn-flat btn-lg" (click)="loginWithGoogle()">
                        <i class="fab fa-google mr-2"></i> Sign in with Google
                      </button> -->
                    </div>
                    <!-- /.col -->
                  </div>
                  <div class="row mt-3" *ngIf="!processing">
                    <div class="col text-left">
                      <a class="text-warning cursor-pointer" [routerLink]="['/login']">Return to login</a>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="login-form">
            <div class="w-100">
              <div class="d-flex justify-content-center flex-column align-items-center">
                <h2 class="text-primary bold">Check your email inbox</h2>
                <p class="bold">We have sent a password recover instructions to your email.</p>
                <p style="max-width: 70%">Tip: Check your spam folder in case the email was incorrectly identified</p>
                <button class="btn btn-lg btn-primary btn-block" (click)="openEmail()">Open Email</button>
                <a class="text-primary bold-500 mt-2" [routerLink]="['/login']">Skip, Ill confirm later</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.login-card-body -->
</div>