import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { PatientAutocompleteComponent } from '../patient-autocomplete/patient-autocomplete.component';

@Component({
  selector: 'app-patient-selection-modal',
  templateUrl: './patient-selection-modal.component.html',
  styleUrls: ['./patient-selection-modal.component.scss']
})
export class PatientSelectionModalComponent implements OnInit {

  @Output()
  select: EventEmitter<any> = new EventEmitter<any>();
  
  @ViewChild('patientComp', {static: true})
  patientComp: PatientAutocompleteComponent;

  patient = null;
  modal = false;

  constructor() {}

  ngOnInit(): void {
  }

  onSelect() {
    this.select.emit(this.patient);
  }

  onOpen() {
    this.modal = true;
  }

  onClose() {
    this.modal = false;
  }
}
