import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getStorage } from '../../helper/storage';
import { appendQueryParams, cas_url } from '../../helper/url';
import { CURRENT_USER } from '../../items/storage-names';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  currentUser: any;

  constructor(private http: HttpClient) {
    try {
      let userData = getStorage(CURRENT_USER);

      if (userData != null) {
        this.currentUser = JSON.parse(userData);
      }
    } catch (e) {
      this.currentUser = null;
    }
  }

  register(data, queryParams = {}) {
    return this.http.post(
      `${cas_url}/users/register${appendQueryParams(queryParams)}`, data
    );
  }

  verifyMobile(id, data, queryParams = {}) {
    return this.http.post(
      `${cas_url}/users/${id}/verify-mobile${appendQueryParams(queryParams)}`, data
    );
  }

  verifyEmail(id, data = {}, queryParams = {}) {
    return this.http.post(
      `${cas_url}/users/${id}/verify-email${appendQueryParams(queryParams)}`, data
    );
  }

  resentVerificationEmail(id, data = {}, queryParams = {}) {
    return this.http.post(
      `${cas_url}/users/${id}/resend-verification-email${appendQueryParams(queryParams)}`, data
    );
  }

  updateUser(id, data = {}, queryParams = {}) {
    return this.http.put(
      `${cas_url}/users/${id}${appendQueryParams(queryParams)}`, data
    );
  }

  resetPassword(email, data = {}, queryParams = {}) {
    return this.http.post(
      `${cas_url}/users/${email}/reset-password${appendQueryParams(queryParams)}`, data
    );
  }

  changePassword(id, data = {}, queryParams = {}) {
    return this.http.put(
      `${cas_url}/user/${id}/change-password${appendQueryParams(queryParams)}`, data
    );
  }

  updatePassword(id, data = {}, queryParams = {}) {
    return this.http.post(
      `${cas_url}/users/${id}/update-password${appendQueryParams(queryParams)}`, data
    );
  }

  agreeServiceAgreement(id, data = {}, queryParams = {}) {
    return this.http.put(
      `${cas_url}/users/${id}/agree-service-contract${appendQueryParams(queryParams)}`, data
    );
  }

  payments(queryParams = {}) {
    return this.http.get(
      `${cas_url}/payments${appendQueryParams(queryParams)}`
    );
  }

  plansShowByCode(code, queryParams = {}) {
    return this.http.get(
      `${cas_url}/plans/show_by_code/${code}${appendQueryParams(queryParams)}`
    );
  }

  getPlans() {
    return this.http.get(`${cas_url}/plans`);
  }

  proceedPayment() {
    return this.http.post(`${cas_url}/user-subscription/proceed-payment`, null);
  }

  finalizePayment(data: any) {
    return this.http.post(`${cas_url}/user-subscription/finalize-payment`, data);
  }

  addCustomerCard(cardDetails: any) {
    return this.http.post(`${cas_url}/user-subscription/customer-cards`, cardDetails);
  }

  getCustomerCards() {
    return this.http.get(`${cas_url}/user-subscription/customer-cards`);
  }

  removeCustomerCard(tokenId: string) {
    return this.http.delete(`${cas_url}/user-subscription/customer-cards/${tokenId}`);
  }

  resendCardVerificationEmail(tokenId: string) {
    return this.http.post(`${cas_url}/user-subscription/customer-cards/email`, { tokenId: tokenId });
  }

  getSubscriptions() {
    return this.http.get(`${cas_url}/user-subscription`);
  }

  getCurrentSubscription() {
    return this.http.get(`${cas_url}/user-subscription/current`);
  }

  createFreeSubscription() {
    return this.http.post(`${cas_url}/user-subscription/create-free-subscription`, null);
  }

  cancelSubscription() {
    return this.http.post(`${cas_url}/user-subscription/cancel`, null);
  }

  users(queryParams = {}) {
    return this.http.get(
      `${cas_url}/users${appendQueryParams(queryParams)}`
    );
  }

  deleteUser(id, queryParams = {}) {
    return this.http.delete(
      `${cas_url}/users/${id}${appendQueryParams(queryParams)}`
    );
  }

  findUser(id, queryParams = {}) {
    return this.http.get(
      `${cas_url}/users/${id}${appendQueryParams(queryParams)}`
    );
  }
}
