
<div class="container-fluid">
    <div class="row">
        <div class="col">
            <h3 class="text-muted">Regular Clinic Schedule</h3>
            <p class="text-muted">For each alloted time range in your daily schedule, choose <strong>START TIME</strong>, <strong>END TIME</strong> and <strong>Schedule Intervals</strong>.</p>
            <p class="text-muted hidden"><strong>Tip: </strong> Use the "Create Bulk Schedule" button to create multiple schedules at once!</p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p-accordion>
                <p-accordionTab header="{{ day?.display }}" *ngFor="let day of days; let i = index" [formGroup]="getScheduleControl(i)"  [selected]="i === 0">
                    <div class="pr-3 pl-3">
                        <table class="table table-striped mb-3">
                            <thead>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Schedule Intervals</th>
                                <th>Note</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let slot of getScheduleControl(getDayIndex(day?.code))?.controls?.slots?.controls; let j = index" formArrayName="slots" >
                                    <td [formGroup]="slot"><input disabled [class.required-border]="slot?.get('slot_start_time')?.errors?.required" type="time" data-target="#reservationdate" data-form-type="date" class="form-control datetimepicker-input" formControlName="slot_start_time"></td>
                                    <td [formGroup]="slot"><input disabled [class.required-border]="slot?.get('slot_end_time')?.errors?.required" type="time" data-target="#reservationdate" data-form-type="date" class="form-control datetimepicker-input" formControlName="slot_end_time"></td>
                                    <td [formGroup]="slot">
                                        <select disabled [class.required-border]="slot?.get('slot_duration')?.errors?.required" id="exampleFormControlSelect1" class="form-control" formControlName="slot_duration">
                                            <option [value]="0.167">Every 10 mins</option>
                                            <option [value]=".25">Every 15 mins</option>
                                            <option [value]=".5">Every 30 mins</option>
                                            <option [value]="1" selected>Every 1 hour</option>
                                            <option [value]="2" selected>Every 2 hours</option>
                                        </select>
                                    </td>
                                    <td [formGroup]="slot"><input [class.required-border]="slot?.get('slot_notes')?.errors?.required" type="text" placeholder="Notes" class="form-control" formControlName="slot_notes"></td>
                                    <td [formGroup]="slot">
                                        <button class="btn btn-danger" (click)="removeSlot(i, j)">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="getScheduleControl(getDayIndex(day?.code))?.value?.slots?.length < 1">
                                    <td colspan="5">No Slots</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pl-3 pb-3 pr-3">
                        <button class="btn btn-primary mr-2 hidden" (click)="addSlot(i)"><i class="fas fa-plus mr-2"></i>Add Slot</button>
                        <button class="btn btn-primary" (click)="generator?.onOpen(getSchedule(i))"><i class="fas fa-clock mr-2"></i>Create Schedule</button>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>
<app-clinic-time-block-generator #generator></app-clinic-time-block-generator>