<form [formGroup]="form" *ngIf="form" (ngSubmit)="send(document)">
  <div class="pr-3 pl-3">
    <div class="form-group mt-4">
      <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label class="btn" [class.active]="!byEmail"
          [ngClass]="{ 'btn-primary' : !byEmail, 'btn-outline-primary' : byEmail }" (click)="toggleReferredBy()">
          <input type="radio" name="options" autocomplete="off" [checked]="!byEmail">Doctor's Name
        </label>
        <label class="btn" [class.active]="byEmail"
          [ngClass]="{ 'btn-primary' : byEmail, 'btn-outline-primary' : !byEmail }" (click)="toggleReferredBy()">
          <input type="radio" name="options" autocomplete="off" [checked]="!byEmail">
          Doctor's E-mail
        </label>
      </div>
    </div>

    <div *ngIf="!byEmail" class="form-group mt-4">
      <span class="bold-500 text-muted">Referred To</span>
      <b class="ml-2 text-danger">*</b>
      <!-- <input type="text" class="form-control" formControlName="referred_to" placeholder="Enter Doctors Name" /> -->
      <app-doctor-autocomplete (select)="setDoctor($event)" (unselect)="clearDoctor()" (clear)="clearDoctor()"
        #doctorComp
        [class.is-invalid]="(!form.get('referred_to').valid && !firstTimeInput) || !form.get('referred_to').valid && form.get('referred_to').touched"
        (search)="firstTimeInput = false;">
      </app-doctor-autocomplete>
      <div class="invalid-feedback"
        *ngIf="(form.get('referred_to').hasError('required') && !firstTimeInput) || !form.get('referred_to').valid && form.get('referred_to').touched">
        Please select a doctor in the search result.
      </div>
    </div>
    <div *ngIf="byEmail" class="form-group mt-4">
      <span class="bold-500 text-muted">Refer by email</span>
      <b class="ml-2 text-danger">*</b>
      <input type="text" class="form-control"
        [class.is-invalid]="!form.get('referred_email').valid && form.get('referred_email').touched"
        formControlName="referred_email" placeholder="Enter doctor's email" />
      <div class="invalid-feedback"
        *ngIf="form.get('referred_email').hasError('required') && form.get('referred_email').touched">
        Please provide an email address.
      </div>
      <div class="invalid-feedback"
        *ngIf="form.get('referred_email').hasError('email') && form.get('referred_email').touched">
        E-mail address is invalid.
      </div>
    </div>
    <div class="form-group mt-4">
      <span class="bold-500 text-muted">Reason for Referral</span>
      <b class="ml-2 text-danger">*</b>
      <input type="text" class="form-control" formControlName="reason_for_referral"
        [class.is-invalid]="!form.get('reason_for_referral').valid && form.get('reason_for_referral').touched"
        placeholder="Enter Reason for Referral" />
      <div class="invalid-feedback"
        *ngIf="form.get('reason_for_referral').hasError('required') && form.get('reason_for_referral').touched">
        Required input.
      </div>
    </div>
    <div class="form-group mt-4">
      <span class="bold-500 text-muted">Chief Complaint</span>
      <b class="ml-2 text-danger" *ngIf="getControl('complaint')?.errors?.required">*</b>
      <input type="text" class="form-control" formControlName="complaint" placeholder="Enter Chief Complaint"
        [class.is-invalid]="!form.get('complaint').valid && form.get('complaint').touched" />
      <div class="invalid-feedback" *ngIf="form.get('complaint').hasError('required') && form.get('complaint').touched">
        Required input.
      </div>
    </div>
    <div class="form-group mt-4">
      <span class="bold-500 text-muted">Pertinent Information</span>
      <b class="ml-2 text-danger">*</b>
      <p-editor formControlName="pertinent_information" [style]="{ height: '320px'}"
        [class.is-invalid]="!form.get('pertinent_information').valid && form.get('pertinent_information').touched">
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <button title="Bold" class="ql-bold"></button>
            <button title="Italic" class="ql-italic"></button>
            <button title="Underline" class="ql-underline"></button>
            <button title="Strike" class="ql-strike"></button>
            <button title="Blockqoute" class="ql-blockquote"></button>
            <button title="Clear Format" class="ql-clean"></button>
          </span>
          <span class="ql-formats">
            <button title="Indent" class="ql-indent" value="-1"></button>
            <button title="Indent" class="ql-indent" value="+1"></button>
          </span>
          <span class="ql-formats">
            <button title="Sub" class="ql-script" value="sub"></button>
            <button title="Super" class="ql-script" value="super"></button>
          </span>
        </ng-template>
      </p-editor>
      <div class="invalid-feedback"
        *ngIf="form.get('pertinent_information').hasError('required') && form.get('pertinent_information').touched">
        Please provide patient information.
      </div>
    </div>
  </div>

  <div class="row flex-row px-3 pb-3 w-100">
    <div class="col">
      <div class="custom-control custom-checkbox"  style="font-size: .9em">
        <input id="aggree" type="checkbox" class="custom-control-input cursor-pointer" (change)="agree = !agree">
        <label for="aggree" class="custom-control-label cursor-pointer"></label>
        <b class="mr text-danger">*</b>&nbsp;
        <span>By clicking the “Finalize and Send Referral” button below, I hereby consent to share patient records
          regarding this referral and acknowledge that I have understood and agreed with the
          <a href="https://www.mwell.com.ph/privacy-policy" class="font-weight-bold" target="_blank">mWell Privacy Policy</a>.
        </span>
      </div>
    </div>
    <div class="row flex-row align-items-end">
      <button role="button" class="btn btn-primary btn-md mr-2" [disabled]="form.disabled || !agree">
        <i *ngIf="form.disabled" class="ml-2 fas fa-circle-notch spinning px-2"></i>
        Finalize and Send Referral
      </button>
    </div>
  </div>
</form>
