import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { extendMoment } from 'moment-range';
import * as _moment from 'moment';
import { PatientService } from 'src/app/utils/services/mpi/patient.service';
import { FormBuilder } from '@angular/forms';
import { getStorage } from 'src/app/utils/helper/storage';
import { CURRENT_USER } from 'src/app/utils/items/storage-names';
import { PatientAutocompleteComponent } from '../../patient-autocomplete/patient-autocomplete.component';
import { HmoMastersService } from 'src/app/utils/services/doctor/hmo-masters.service';

@Component({
  selector: 'app-create-inpatient-modal',
  templateUrl: './create-inpatient-modal.component.html',
  styleUrls: ['./create-inpatient-modal.component.scss']
})
export class CreateInpatientModalComponent implements OnInit {

  @Output()
  saved: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('patientComp', { static: true })
  patientComp: PatientAutocompleteComponent;

  user: any;
  modal = false;
  date: any;
  complaint: any;
  hospital: any;
  attending_physician: any;
  item: any;
  patient: any;
  searching = false;
  processing = false;
  editMode: Boolean;
  location_room: any;
  health_facility_code_short: any;
  hospital_short_name: any;
  hmo_code: any;
  discharge_remarks: any;
  admission_datetime: any;
  discharge_datetime = null;
  visit: any;
  hmo_masters: any;
  selectedAttribute = [null, null, null];
  hospitalNotSpecified: string = 'Hospital not specified';

  constructor(
    private service: PatientService,
    private toastr: ToastrService,
    public hmoMastersService: HmoMastersService,
  ) { }

  ngOnInit(): void {
    this.user = getStorage(CURRENT_USER, true);
    this.editMode = false;
    this.hmoMasters();
  }

  onOpen(visit = null, patient = null) {
    this.editMode = visit ? Object.keys(visit).length !== 0 : false;
    this.patientComp.selected = this.patient = visit?.patient;
    this.visit = visit;

    if (this.editMode) {
      this.health_facility_code_short = this.visit.health_facility_code_short || this.hospitalNotSpecified;
      this.location_room = this.visit.location_room;
      this.complaint = this.visit.chief_complaint;
      this.hospital_short_name = this.visit.hospital_short_name;
      this.attending_physician = this.visit.attending_physician || this.user?.doctor?.primary_name?.full;
      const moment = extendMoment(_moment);
      this.admission_datetime = moment(this.visit.admission_datetime).format('YYYY-MM-DDTHH:mm');
      this.hmo_code = this.visit.hmo_code;

      if (this.visit?.discharge_datetime) {
        this.discharge_datetime = moment(this.visit.discharge_datetime).format('YYYY-MM-DDTHH:mm');
        this.discharge_remarks = this.visit.discharge_remarks;
      }

    } else {
      this.health_facility_code_short = this.hospitalNotSpecified;
      this.hospital_short_name = this.hospital?.hospital_master?.facility_name;
      this.hmo_code = null;
      const moment = extendMoment(_moment);
      this.admission_datetime = moment().format('YYYY-MM-DDTHH:mm');
      this.patientComp.selected = this.patient = patient;
      this.location_room = null;
      this.complaint = null;
      this.hospital_short_name = null;
      this.attending_physician = null;
    }

    this.searching = false;
    this.processing = false;
    this.modal = true;
  }

  onClose() {
    this.modal = false;
  }

  addAsInPatient() {
    console.log('this.health_facility_code_short: ', this.health_facility_code_short);

    this.service.addAsInpatient(this.patient?.patient_id, {
      patient_id: this.patient?.patient_id,
      health_facility_code_short: this.health_facility_code_short !== this.hospitalNotSpecified ? this.health_facility_code_short : null,
      complaint: this.complaint,
      location_room: this.location_room,
      discharge_remarks: this.discharge_remarks,
      hospital_short_name: this.hospital_short_name || this.hospitalNotSpecified,
      hmo_code: this.hmo_code,
      attending_physician: this.attending_physician || this.user?.doctor?.primary_name?.full,
      md_id: this.user?.doctor?.md_id,
      date: this.date,
      visit_id: this.visit?.visit_id,
      admission_datetime: this.admission_datetime,
      discharge_datetime: this.discharge_datetime,
    }).subscribe((response: any) => {
      this.modal = false;
      this.saved.emit();
      this.toastr.success("Successfully added as Inpatient")
    }, (err) => {
      this.processing = false;
    });

  }

  setPatient(patient) {
    this.patient = patient;
  }

  clearPatient() {
    this.patient = null;
  }

  hospitalAffiliations() {
    return this.user?.doctor?.hospital_affiliations;
  }

  hmoMasters() {
    const queryParams: any = {};
    queryParams.order = 'hmo_text';
    queryParams.sort = 'asc';
    this.hmoMastersService.getHMOMasters(queryParams)
      .subscribe((response: any) => {
        this.hmo_masters = response.data;
      });
  }

  selectedHospital(event) {
    this.hospital_short_name = this.hospital?.hospital_master?.facility_name;
  }

}
