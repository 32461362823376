import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-template-modal',
  templateUrl: './custom-template-modal.component.html',
  styleUrls: ['./custom-template-modal.component.scss']
})
export class CustomTemplateModalComponent implements OnInit {
  modal = false;
  processing = false;
  fieldCategoryOptions: any[];
  field_category = '';

  constructor() { }

  ngOnInit(): void {
    this.fieldCategoryOptions = [
      {
        label: 'Admitting Orders',
        value: 'admitting-orders'
      },
      // {
      //   label: 'Basic Clinical Note',
      //   value: 'basic-clinical-note'
      // },
      // {
      //   label: 'Blank Scribble Notes',
      //   value: 'blank-scribble-notes'
      // },
      // {
      //   label: 'Brief History Prior to Admission',
      //   value: 'brief-history-prior-to-admission'
      // },
      // {
      //   label: 'Medical Certificate Consultation Details',
      //   value: 'medical-certificate-consultation-details'
      // },
      // {
      //   label: 'Medical Certificate Notes and Patient Instructions',
      //   value: 'medical-certificate-notes-and-patient-instructions'
      // },
      {
        label: 'Subjective',
        value: 'subjective'
      },
      {
        label: 'Objective',
        value: 'objective'
      },
      {
        label: 'Patient Instructions',
        value: 'patient-instructions'
      },
      // {
      //   label: 'Physical Examination Scribble Notes',
      //   value: 'physical-examination-scribble-notes'
      // },
    ];
  }

  onOpen() {
    this.modal = true;
  }

  onClose() {
    this.modal = false;
  }

}
