export const sex = [
  {
    value: 'M',
    display: 'Male',
    shortDisplay: 'M',
  },
  {
    value: 'F',
    display: 'Female',
    shortDisplay: 'F',
  },
  {
    value: 'U',
    display: 'Unknown',
    shortDisplay: 'U',
  },
];
