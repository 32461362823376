import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { extendMoment } from 'moment-range';
import * as _moment from 'moment';
import { ScheduleService } from 'src/app/utils/services/schedule/schedule.service';
import { FormBuilder } from '@angular/forms';
import { getStorage } from 'src/app/utils/helper/storage';
import { CURRENT_USER } from 'src/app/utils/items/storage-names';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { VIRTAUL_CLINIC } from 'src/app/utils/items/clinic-type';
import { AppService } from 'src/app/utils/services/app.service';
import { Router } from '@angular/router';
import { Checkbox } from 'primeng/checkbox';
import { PatientService } from 'src/app/utils/services/mpi/patient.service';

@Component({
  selector: 'app-merge-confirmation-modal',
  templateUrl: './merge-confirmation-modal.component.html',
  styleUrls: ['./merge-confirmation-modal.component.scss']
})
export class MergeConfirmationModalComponent implements OnInit {

  @Output()
  saved: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  clinics: any;

  @Input()
  item: any;

  @Input()
  _patientOne: number;

  @Input()
  _patientTwo: number;

  modal = false;
  processing = false;
  patient: any;
  checked: boolean;
  mergeResponse: any;
  _doctorId: string;
  user: any;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private PatientService: PatientService
  ) { }

  ngOnInit(): void {
    this.user = getStorage(CURRENT_USER, true);
  }

  mergePatient() {
    this.processing = true;


    if (confirm('Do you want to continue?') == true) {

      const payload = {
        merged_id: this._patientTwo,
        retained_id: this._patientOne,
        doctor_id: this.user.doctor.md_id
      };

      this.mergeResponse = this.PatientService.mergeTwoPatients(payload).subscribe((response: any) => {
        console.log('Merging Patient:', response);
        this.processing = false;
        if (response.message) {
          this.modal = false;
          this.toastr.success(response.message);
          this.router.navigateByUrl('/patients');
        } else {
          this.toastr.error(response.error);
        }
      }, (err) => {
        this.processing = false;
        this.toastr.error('Unable to create appointment at this time.');
      });

      // setTimeout(() => {

      //   this.checked = false;
      //   this.processing = false;


      //   setTimeout(() => {
      //     this.modal = false;
      //     this.router.navigateByUrl('/patients');
      //   }, 1000);
      // }, 1000);
    } else {
      this.processing = false;
    }

  }

  onOpen(patient = null, selectedService = null) {
    this.processing = false;
    this.modal = true;
  }



  onClose() {
    this.modal = false;
  }



  setPatient(patient) {
    this.patient = patient;
  }

  clearPatient() {
    this.patient = null;
  }

}
