<div>
  <div class="card light-shadow position-relative">
    <div class="grid">
      <div class="row">
        <div class="col-8 detail-section">
          <div class="gradient-background">
            <img class="ml-4" src="assets/img/mwell-login-logo-new.png" width="180px">
            <h1>Simplifying Practice <br> Management and <br> Electronic Medical <br> Records</h1>
            <div class="position-relative">
              <ul>
                <li><i class="fa fa-check checkbox"></i>Digitized Patient Medical Records, History and Monitoring</li>
                <li><i class="fa fa-check checkbox"></i>Important Images and Document Management</li>
                <li><i class="fa fa-check checkbox"></i>Inpatient and Outpatient Tracking</li>
                <li><i class="fa fa-check checkbox"></i>Unlimited Number of Patients</li>
                <li><i class="fa fa-check checkbox"></i>Unlimited GB of Storage</li>
                <li><i class="fa fa-check checkbox"></i>Basic Analytics and Responsive Tech Support</li>
                <li><i class="fa fa-check checkbox"></i>Payments & Billings</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-4 login-form-section">
          <div class="welcome-text">Welcome To </div>
          <div class="login-logo">
            <img class="ml-4 mb-4 text-center" src="assets/img/logo.png" width="300px">
          </div>
          <!-- <div class="btn-group login-button-group" role="group">
            <button type="button" class="btn p-2" (click)="mwellLogin()"><i class="fas fa-user-md  mr-2"></i> Doctor</button>
            <button type="button" class="btn active p-2"><i class="fa fa-user mr-2"></i> Staff</button>
          </div> -->
          <div class="staff-login">
            <div class="login-label">Sign in with your email address</div>
            <form [formGroup]="loginForm">
              <div class="form-group">
                <label for="emailInput">Email address</label>
                <input id="emailInput" formControlName="email" type="email" class="form-control" placeholder="Email" />
              </div>
              <div class="form-group position-relative">
                <label for="passwordInput">Password</label>
                <input id="passwordInput" formControlName="password" [type]="getPasswordType()" class="form-control" placeholder="Password">
                <i (click)="triggerShowPassword()" *ngIf="!showPassword" class="fa fa-eye password-eye"></i>
                <i (click)="triggerShowPassword()" *ngIf="showPassword" class="fa fa-eye-slash password-eye"></i>
              </div>
              <div class="form-check mb-4 position-relative">
                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                <label class="form-check-label" for="exampleCheck1">Remember me</label>
                <a class="forgot-password-link" href="javascript:;">Forgot Password?</a>
              </div>
              <button [disabled]="loginForm?.invalid" (click)="logIn()" type="submit" class="btn btn-primary btn-block">Sign In</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <img class="login-doctor" src="assets/img/Login-Doctor.png">
</div>


