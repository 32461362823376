<p-autoComplete
showEmptyMessage="true"
appendTo="body"
baseZIndex="200"
styleClass="autocomplete-form"
placeholder="Search Doctor"
inputStyleClass="form-control"
panelStyleClass="autocomplete-form-panel"
field="primary_name.professional"

[(ngModel)]="selected"
[suggestions]="results"
(onClear)="onClear($event)"
(onUnselect)="onUnselect($event)"
(onSelect)="onSelect($event)"
(completeMethod)="onSearch($event)"
>
</p-autoComplete>
