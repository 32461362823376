import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appendQueryParams, doctor_url } from '../../helper/url';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(private http: HttpClient) {}
  
  getBillings(queryParams = {}) {
    return this.http.get(
      `${doctor_url}/billing${appendQueryParams(queryParams)}`
    );
  }

  getBilling(id, queryParams = {}) {
    return this.http.get(
      `${doctor_url}/billing/${id}${appendQueryParams(queryParams)}`
    );
  }

  voidBill(id, queryParams = {}) {
    return this.http.put(
      `${doctor_url}/billing/${id}/void${appendQueryParams(queryParams)}`, {}
    );
  }

  saveBilling(data, queryParams = {}) {
    return this.http.post(
      `${doctor_url}/billing/save${appendQueryParams(queryParams)}`, data
    );
  }

  recordPayment(billingID, data) {
    return this.http.post(
      `${doctor_url}/billing/${billingID}/record-payment`, data
    );
  }

  getBillableItems(queryParams = {}) {
    return this.http.get(
      `${doctor_url}/billable-items${appendQueryParams(queryParams)}`
    );
  }

  getBillableCategories(queryParams = {}) {
    return this.http.get(
      `${doctor_url}/billable-category${appendQueryParams(queryParams)}`
    );
  }

  saveBillableItem(data, queryParams = {}) {
    return this.http.post(
      `${doctor_url}/billable-item/save${appendQueryParams(queryParams)}`, data
    );
  }

  getBillableItem(id, queryParams = {}) {
    return this.http.get(
      `${doctor_url}/billable-item/${id}${appendQueryParams(queryParams)}`, {}
    );
  }

  deactivateBillableItem(id, queryParams = {}) {
    return this.http.put(
      `${doctor_url}/billable-item/${id}/deactivate${appendQueryParams(queryParams)}`, {}
    );
  }

  reactivateBillableItem(id, queryParams = {}) {
    return this.http.put(
      `${doctor_url}/billable-item/${id}/reactivate${appendQueryParams(queryParams)}`, {}
    );
  }

  updateBillableItem(id,data, queryParams = {}) {
    return this.http.put(
      `${doctor_url}/billable-item/${id}/update${appendQueryParams(queryParams)}`, data
    );
  }
  deleteBilling(id, queryParams = {}) {
    return this.http.delete(
      `${doctor_url}/billing/${id}${appendQueryParams(queryParams)}`
    );
  }

  deletePayment(billingID, id, queryParams = {}) {
    return this.http.delete(
      `${doctor_url}/billing/${billingID}/delete-payment/${id}`
    );
  }

  downloadFile(id, queryParams = {}) {
    return this.http.get(
      `${doctor_url}/file/${id}/download${appendQueryParams(queryParams)}`, {
        responseType: 'blob'
      }
    );
  }

}
